import React, { useCallback, useState, useRef, useEffect } from "react";
import {
  Paper,
  Grid,
  Button,
  OutlinedInput,
  TableContainer,
  Stack,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Box,
  ButtonGroup,
} from "@mui/material";
import PropTypes from "prop-types";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";
import swal from "sweetalert";
import { makeStyles } from "@material-ui/core/styles";
import BACoin from "../../assets/Balance Available Coins.png";
import SearchIcon from "@material-ui/icons/Search";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import config from "../../aws-exports";
import MyCoin from "../../assets/My Coins.png";
import { fetchData } from "../../api/api";
import noThingIsHere from "../../assets/Nothing here yet.gif";

export default function MyStore(props) {
  const { data, refetch, userDetails, isLoading } = props;
  const useStyles = makeStyles((theme) => ({
    paper: {
      // padding: "1rem",
      paddingBottom: "2rem",
      marginBottom: "2rem",
      boxShadow:
        "0px 2px 14px -1px rgb(0 0 0 / 10%), 0px 4px 5px 0px rgb(0 0 0 / 4%), 0px 1px 0px 0px rgb(0 0 0 / 2%)!important",
    },
    buttonSection: {
      textAlign: "center",
      margin: "1rem",
      width: "100%",
    },
    button: {
      backgroundColor: "var(--color-button-background)!important",
      fontSize: "1.5rem!important",
      textTransform: "capitalize!important",
      fontFamily: `myfontregular!important`,
    },
    redeemButton: {
      backgroundColor: "var(--color-button-background)!important",
      fontSize: "1.5rem!important",
      padding: "2px 10px!important",
      textTransform: "capitalize!important",
      fontFamily: `myfontregular!important`,
    },
    mainCard: {
      width: "32rem",
      boxShadow:
        userDetails.themeDark === "dark"
          ? "none !important"
          : "rgba(128, 128, 128, 0.46) 0px 0px 10px 0px!important",
      padding: "1rem",
      // margin: "1rem 0.5rem",
      cursor: "pointer",
      backgroundColor:
        userDetails.themeDark === "dark"
          ? "#3e4655 !important"
          : theme.palette.background.paper,
      "&:hover": {
        transition: "transform .3s",
        transform: "scale(1.1)",
        boxShadow:
          userDetails.themeDark === "dark"
            ? "none !important"
            : "rgba(128, 128, 128, 0.46) 0px 0px 20px 0px!important",
      },
      "@media (max-width: 450px)": {
        width: "100%",
        overflow: "auto",
        "&:hover": {
          transition: "none",
          transform: "none",
        },
      },
    },
    addItemCounter: {
      display: "flex",
      marginRight: "6px",
      boxShadow: "0 0 4px 0 hsla(0,0%,0%,0.2)",
      backgroundColor: "white",
      "& .MuiBadge-root": {
        marginRight: 4,
      },
      "& > div > button": {
        color: "black",
        boxShadow: "0 0 4px 0 hsla(0,0%,0%,0.5)",
        border: "none",
        padding: "4px 7px",
        minWidth: "3rem!important",
      },
    },
    headerSectionTab: {
      marginBottom: "2rem",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      "@media (max-width: 900px)": {
        display: "grid",
        gap: "2rem",
        justifyContent: "center",
      },
      "& > div": {
        width: "100%",
        "@media (min-width: 901px)": {
          width: "37rem",
        },
      },
    },
    headerBlock: {
      padding: "1rem",
      display: "flex",
      boxShadow: "0 0 4px 0 hsla(0,0%,50%,.3607843137)",
      borderRadius: "5px",
      backgroundColor: "#FDE1AB",
      gap: "1rem",
      alignItems: "center",
      "& > h2": {
        color: "black",
      },
      "& > span": {
        color: "black",
      },
    },
    sideButton: {
      border: "none",
      padding: "2px",
      borderRadius: "7px",
      boxShadow: "rgba(128, 128, 128, 0.56) 0px 0px 5px 0px",
      cursor: "pointer",
    },
    tableContainerSection: {
      marginTop: "2rem",
      display: "flex",
      flexWrap: "wrap",
      gap: "2rem",
      justifyContent: "center",
      padding: "2rem",
      "@media (max-width: 450px)": {
        padding: "0.5rem",
      },
    },
    imageCardSection: {
      width: "100%",
      textAlign: "center",
      height: "18rem",
      backgroundColor: "white",
      justifyContent: "center",
      display: "flex",
      padding: "1rem 0rem",
      overflow: "hidden",
    },
    footerCardSection: {
      padding: "1rem 0rem",
      display: "flex",
      gap: "1rem",
      alignItems: "center",
      justifyContent: "space-between",
    },
    gapingObject: {
      display: "flex",
      gap: "0.5rem",
      alignItems: "center",
      "& > h3": {
        fontSize: "1.5rem",
        color:
          userDetails.themeDark === "dark"
            ? "white"
            : "var(--color-button-background) !important",
      },
    },
    searchInput: {
      fontSize: "1.8rem",
      // color: inherit;
      backgroundColor: "#fff!important",
      color: "black!important",
      border: "none",
      borderRadius: "1rem",
      transition: "all 0.2s",
      marginRight: "-3.25rem",
      padding: "7px 36px 7px 20px",
      width: "100%",
      boxShadow: "0 0 4px 0 hsla(0,0%,50%,.3607843137)",
    },
    mainDialog: {
      "& > div > div": {
        margin: 0,
        backgroundColor:
          userDetails.themeDark === "dark"
            ? "#1C2027 !important"
            : theme.palette.background.paper,
        color:
          userDetails.themeDark === "dark" ? "white" : "#1C2027 !important",
        "@media (max-width: 750px)": {
          width: "90%!important",
        },
        "& > h2 > button ": {
          color:
            userDetails.themeDark === "dark"
              ? "white !important"
              : "#1C2027 !important",
          "& > svg ": {
            fontSize: "2rem",
          },
        },
      },
    },
    secondaryButton: {
      fontSize: "1.5rem!important",
      textTransform: "capitalize!important",
      color: "var(--color-button-background)!important",
      borderColor: "var(--color-button-background)!important",
    },
    countStyle: {
      margin: "auto 1rem",
      fontSize: "1.5rem",
      color: "black",
    },
    sortAndfilter: {
      display: "flex",
      alignItems: "center",
      gap: "1rem",
      justifyContent: "end",
    },
    responsiveImage: {
      width: "30%",
      "@media (max-width: 600px)": {
        width: "65%",
      },
    },
  }));
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [rewards, setRewards] = useState(data?.rewards);
  const myAvailableCoinRef = useRef(data?.myavailablecoin);
  const [count, setCount] = React.useState(0);
  const [myRedeem, setMyRedeem] = useState();
  //const reflpoints = useRef();
  // data?.mystore.sort((a, b) => a.level - b.level);
  useEffect(() => {
    setRewards(data.rewards);
    return () => {};
  }, [data?.rewards]);

  const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialog-paper": {
      width: "50%",
      maxWidth: "none",
    },
    "& .MuiDialogContent-root": {
      padding: "1rem 2rem",
    },
    "& .MuiDialogTitle-root": {
      padding: "1rem 1rem 1rem 2rem",
    },
    "& .MuiDialogActions-root": {
      padding: theme.spacing(1),
    },
  }));
  function BootstrapDialogTitle(props) {
    const { children, onClose, ...other } = props;

    return (
      <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  }
  const handleClose = () => {
    setOpen(false);
  };
  const handleClickOpen = (item) => {
    setMyRedeem(item);
    setOpen(true);
  };
  const handleMySoreSave = async () => {
    if (
      myRedeem.qty === undefined ||
      myRedeem.qty === null ||
      myRedeem.qty < 1
    ) {
      swal("Oops!", "Please select at least one item", "error");
      return true;
    }
    var body = {
        rid: myRedeem.rid,
        ur_id: userDetails?.uData?.ur_id,
        qty: myRedeem.qty,
        pdate: 1,
        schema: config.schema,
      },
      apiname = "/updateRewardsRequest";

    await fetchData(body, apiname,userDetails);
    //reflpoints.current = '';
    swal({
      title: "Congratulations on your redemption!",
      text: "Your order will be approved by Manager or Admin soon",
      icon: "success",
    });
    setOpen(false);
    refetch();
    rewards.forEach((fritem, index) => {
      rewards[index].userqty = false;
      rewards[index].qty = 0;
    });
    setRewards(rewards);
    setCount(0);
  };

  BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
  };
  const handleIncreaseClick = useCallback((item, index) => {
    //rewards
    let isValid = true;
    rewards.forEach((fritem) => {
      if (fritem.userqty) {
        isValid = false;
        return; // Exit the loop early if any item is invalid
      }
    });

    //let tval = (parseInt(item.qty) === 0 ? parseInt(item.points) : (parseInt(item.qty)) * parseInt(item.points));

    if (
      (isValid || item.userqty) &&
      parseInt(item.points) <= parseInt(myAvailableCoinRef.current)
    ) {
      item.qty = parseInt(item.qty) + 1;
      item.userqty = true;
      myAvailableCoinRef.current =
        parseInt(myAvailableCoinRef.current) - parseInt(item.points);
      setCount(item.qty);
      // Perform actions if all items are valid, such as proceeding with the purchase.
    }
  }, []);
  const handlereduceClick = useCallback((item) => {
    if (parseInt(item.qty) !== 0) {
      myAvailableCoinRef.current =
        parseInt(myAvailableCoinRef.current) + parseInt(item.points);
      item.qty = parseInt(item.qty) - 1;
      if (item.qty === 0) {
        item.userqty = false;
      }
      setCount(parseInt(item.qty));
    }
  }, []);

  return (
    <>
      <div className={classes.headerSectionTab}>
        <div className={classes.headerBlock}>
          <img
            src={BACoin}
            alt="No Image"
            title="No Image"
            style={{ width: "4rem" }}
          />
          <h2>{myAvailableCoinRef.current}</h2>
          <span>{config.aws_org_id.toLowerCase() === "icici" ? "Points Available ": `${userDetails?.languagejson?.Gamification?.Coins_Available ||
            "Coins Available"}`}</span>
        </div>
        <div style={{ borderRadius: "5px" }}>
          <input
            type="search"
            className={classes.searchInput}
            placeholder={`${userDetails?.languagejson?.Gamification?.Search ||
              "Search products here..."}`}
            // value={search}
            onChange={(e) => {
              setRewards(
                data.rewards?.filter((item) =>
                  item.rname
                    .toLowerCase()
                    .includes(e.target.value.toLowerCase())
                )
              );
            }}
            // onKeyPress={(event) => { }}
          />
          <button
            className="search__button"
            title="Search"
            style={{ position: "relative", top: "0.8rem" }}
            // onClick={() => { }}
          >
            <SearchIcon
              style={{
                fontSize: "2.6rem",
                marginTop: "0.5rem",
                color: "gray",
              }}
            />
          </button>
        </div>
      </div>
      {rewards && rewards.length > 0 && (
        <TableContainer className={classes.tableContainerSection}>
          {!isLoading &&
            rewards?.map((item, index) => (
              <Paper className={classes.mainCard} key={item.rid}>
                <div className={classes.imageCardSection}>
                  <img
                    src={`https://${
                      config.DOMAIN
                    }/${config.aws_org_id.toLowerCase()}-resources/images/reward-images/${
                      item.url
                    }`}
                    alt="No Image"
                    title={item.rname}
                    width="fit-content"
                  />
                </div>
                <h3 style={{ fontWeight: "500", marginTop: "1rem" }}>
                  {item.rname}
                </h3>
                <div className={classes.footerCardSection}>
                  <div className={classes.gapingObject}>
                    <img
                      src={MyCoin}
                      alt="Balance Available Coins"
                      title={config.aws_org_id.toLowerCase() !== "icici" ? "Balance Available Points ": "Balance Available Coins"}
                      style={{ width: "3rem" }}
                    />
                    <h3>{item.points}</h3>
                  </div>
                  <div className={classes.gapingObject}>
                    <Box className={classes.addItemCounter}>
                      <ButtonGroup>
                        <Button
                          aria-label="reduce"
                          onClick={() => handlereduceClick(item)}
                        >
                          <RemoveIcon style={{ fontSize: "2rem" }} />
                        </Button>
                        <div className={classes.countStyle}>{item.qty}</div>
                        <Button
                          aria-label="increase"
                          onClick={() => handleIncreaseClick(item, index)}
                        >
                          <AddIcon style={{ fontSize: "2rem" }} />
                        </Button>
                      </ButtonGroup>
                    </Box>
                    <Button
                      variant="contained"
                      className={classes.redeemButton}
                      onClick={() => handleClickOpen(item)}
                      disabled={parseInt(item.qty) === 0 || !item.userqty}
                    >
                      {" "}
                      {`${userDetails?.languagejson?.Gamification?.Redeem ||
                        "Redeem"}`}
                    </Button>
                  </div>
                </div>
              </Paper>
            ))}

          {/* <Paper className={classes.mainCard}>
                    <div className={classes.imageCardSection}>
                      <img src={cycle} alt="Balance Available Coins" title="Balance Available Coins" />
                    </div>
                    <h3 style={{fontWeight: "500"}}>Sports Bicycle</h3>
                    <div className={classes.footerCardSection}>
                      <div className={classes.gapingObject}>
                        <img src={MyCoin} alt="Balance Available Coins" title="Balance Available Coins" style={{width:"3rem"}} />
                        <h3>1000</h3>
                      </div>
                      <div className={classes.gapingObject}>
                        <Box className={classes.addItemCounter} >
                          <ButtonGroup>
                            <Button
                              aria-label="reduce"
                              onClick={() => {
                                setCount(Math.max(count - 1, 0));
                              }}
                            >
                              <RemoveIcon style={{fontSize:"2rem"}} />
                            </Button>
                            <div className={classes.countStyle}>{count}</div>
                            <Button
                              aria-label="increase"
                              onClick={() => {
                                setCount(count + 1);
                              }}
                            >
                              <AddIcon style={{fontSize:"2rem"}} />
                            </Button>
                          </ButtonGroup>
                        </Box>
                        <Button variant="contained" className={classes.redeemButton} onClick={handleClickOpen2} disabled>Redeem</Button>
                      </div>
                    </div>
                  </Paper>  */}
        </TableContainer>
      )}
      {!rewards ||
        (rewards.length === 0 && (
          <>
            <div style={{ textAlign: "center" }}>
              <img src={noThingIsHere} alt="logo" className={classes.responsiveImage} />
            </div>
          </>
        ))}
      <div>
        {/* Dialog 2*/}
        <div>
          <BootstrapDialog
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={open}
            className={classes.mainDialog}
          >
            <BootstrapDialogTitle
              id="customized-dialog-title"
              onClose={handleClose}
            >
              <h1>{`${userDetails?.languagejson?.Gamification?.Confirmation ||
                "Confirmation"}`}</h1>
            </BootstrapDialogTitle>
            <DialogContent>
              <Typography gutterBottom>
                <p style={{ fontSize: "1.5rem", marginBottom: "5rem" }}>
                  {`${userDetails?.languagejson?.Gamification
                    ?.Do_you_want_to_redeem ||
                    "Do you want to redeem the"}`}{" "}
                  {myRedeem?.rname}?
                </p>
              </Typography>
            </DialogContent>
            <DialogActions>
              <div className={classes.buttonSection}>
                <Button
                  variant="outlined"
                  className={classes.secondaryButton}
                  onClick={handleClose}
                >
                  {" "}
                  {`${userDetails?.languagejson?.Gamification?.No_Cancel ||
                    "No, Cancel"}`}
                </Button>
              </div>
              <div className={classes.buttonSection}>
                <Button
                  variant="contained"
                  className={classes.button}
                  onClick={handleMySoreSave}
                >
                  {`${userDetails?.languagejson?.Gamification?.Yes_Continue ||
                    "Yes, Continue"}`}
                </Button>
              </div>
            </DialogActions>
          </BootstrapDialog>
        </div>
      </div>
    </>
  );
}
