import React, { useCallback, useState, useRef } from "react";
import {
  Paper,
  Button,
  TableContainer,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Box,
  ButtonGroup,
  Table,
  TableBody,
  TableRow,
  TableHead,
  TextField,
  TableCell,
  tableCellClasses,
} from "@mui/material";

import PropTypes from "prop-types";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";
import { makeStyles } from "@material-ui/core/styles";
import BACoin from "../../assets/Balance Available Coins.png";
import TCCoin from "../../assets/Total Coins collected till date.png";
import config from "../../aws-exports";

import noThingIsHere from "../../assets/Nothing here yet.gif";

export default function MyCoins(props) {
  const { data, refetch, userDetails } = props;
  const useStyles = makeStyles((theme) => ({
    paper: {
      // padding: "1rem",
      paddingBottom: "2rem",
      marginBottom: "2rem",
      // boxShadow:
      //   "0px 2px 14px -1px rgb(0 0 0 / 10%), 0px 4px 5px 0px rgb(0 0 0 / 4%), 0px 1px 0px 0px rgb(0 0 0 / 2%)!important",
      boxShadow: "none!important",
    },
    buttonSection: {
      textAlign: "center",
      margin: "1rem",
      width: "100%",
    },
    button: {
      backgroundColor: "var(--color-button-background)!important",
      fontSize: "1.5rem!important",
      textTransform: "capitalize!important",
    },
    headerSectionTab: {
      marginBottom: "2rem",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      "@media (max-width: 900px)": {
        display: "grid",
        gap: "2rem",
        justifyContent: "center",
      },
      "& > div": {
        width: "100%",
        "@media (min-width: 901px)": {
          width: "37rem",
        },
      },
    },
    headerBlock: {
      width: "37rem",
      padding: "1rem",
      display: "flex",
      boxShadow: "0 0 4px 0 hsla(0,0%,50%,.3607843137)",
      borderRadius: "5px",
      backgroundColor: "#FDE1AB",
      gap: "1rem",
      alignItems: "center",
      "@media (max-width: 700px)": {
        width: "100%",
      },
      "& > h2": {
        color: "black",
      },
      "& > span": {
        color: "black",
      },
    },
    sideButton: {
      border: "none",
      padding: "2px",
      borderRadius: "7px",
      boxShadow: "rgba(128, 128, 128, 0.56) 0px 0px 5px 0px",
      cursor: "pointer",
    },
    headerBlockTwo: {
      marginBottom: "2rem",
      display: "flex",
      gap: "3rem",
      alignItems: "center",
      "@media (max-width: 900px)": {
        display: "grid",
        gap: "2rem",
        justifyContent: "center",
      },
    },
    mainDialog: {
      "& > div > div": {
        margin: 0,
        backgroundColor:
          userDetails.themeDark === "dark"
            ? "#1C2027 !important"
            : theme.palette.background.paper,
        color:
          userDetails.themeDark === "dark" ? "white" : "#1C2027 !important",
        "& > h2 > button ": {
          color:
            userDetails.themeDark === "dark"
              ? "white !important"
              : "#1C2027 !important",
          "& > svg ": {
            fontSize: "2rem",
          },
        },
      },
    },
    secondaryButton: {
      fontSize: "1.5rem!important",
      textTransform: "capitalize!important",
      color: "var(--color-button-background)!important",
      borderColor: "var(--color-button-background)!important",
    },
    tableContainerStyle: {
      backgroundColor:
        userDetails.themeDark === "dark"
          ? "#1C2027 !important"
          : theme.palette.background.paper,
    },
    sortAndfilter: {
      display: "flex",
      alignItems: "center",
      gap: "1rem",
      justifyContent: "end",
    },
    responsiveImage: {
      width: "30%",
      "@media (max-width: 600px)": {
        width: "65%",
      },
    },
  }));
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [count, setCount] = React.useState(1);
  const [myRedeem, setMyRedeem] = useState();

  const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialog-paper": {
      width: "50%",
      maxWidth: "none",
    },
    "& .MuiDialogContent-root": {
      padding: "1rem 2rem",
    },
    "& .MuiDialogTitle-root": {
      padding: "1rem 1rem 1rem 2rem",
    },
    "& .MuiDialogActions-root": {
      padding: theme.spacing(1),
    },
  }));
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(even)": {
      backgroundColor:
        userDetails.themeDark === "dark"
          ? "#1b212f!important"
          : theme.palette.action.hover,
    },
    "&:nth-of-type(odd)": {
      backgroundColor:
        userDetails.themeDark === "dark" ? "#0b111d!important" : "white",
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "var(--color-button-background)",
      color: theme.palette.common.white,
      fontSize: "1.5rem",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: "1.5rem",
      color:
        userDetails.themeDark === "dark"
          ? "white !important"
          : "#3F434D !important",
      borderBottom: "1px solid",
      borderColor:
        userDetails.themeDark === "dark" ? "#0b111d!important" : "white",
    },
  }));
  function BootstrapDialogTitle(props) {
    const { children, onClose, ...other } = props;

    return (
      <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  }
  const handleClose = () => {
    setOpen(false);
  };

  BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
  };

  return (
    <>
      <div className={classes.headerBlockTwo}>
        <div className={classes.headerBlock}>
          <img
            src={BACoin}
            alt="Coins Available"
            title={config?.aws_org_id?.toLocaleLowerCase() === "icici" ? "Points Available" :"Coins Available"}
            style={{ width: "4rem" }}
          />
          <h2>{data?.myavailablecoin}</h2>
          <span>{config?.aws_org_id?.toLocaleLowerCase() === "icici" ? "Points Available" : `${userDetails?.languagejson?.Gamification?.Coins_Available ||
            "Coins Available"}`}</span>
        </div>
        <div
          className={classes.headerBlock}
          style={{ backgroundColor: "#E2D2FE" }}
        >
          <img
            src={TCCoin ?? 0}
            alt="Coins Collected Till Date"
            title={config?.aws_org_id?.toLocaleLowerCase() === "icici" ? "Points Collected Till Date" : "Coins Collected Till Date"}
            style={{ width: "4rem" }}
          />
          <h2>{data?.tcoins}</h2>
          <span>{config?.aws_org_id?.toLocaleLowerCase() === "icici" ? "Points Collected Till Date" : `${userDetails?.languagejson?.Gamification
            ?.Coins_Collected_Till_Date || "Coins Collected Till Date"}`}</span>
        </div>
      </div>
      {/* <div className={classes.headerSectionTab}>
        <h2>Transaction Details of coins earned and spent</h2>                  
        <div className={classes.sortAndfilter}>
          <span>Filter by</span>
          <button className={classes.sideButton}>
            <FilterAltOutlinedIcon style={{ fontSize: "3rem"}}/>
          </button>
        </div>
      </div> */}

      <Paper className={classes.paper}>
        <h2 style={{ marginBottom: "2rem" }}>
          {config?.aws_org_id?.toLocaleLowerCase() === "icici" ? "Transaction details of points earned and spent" : `${userDetails?.languagejson?.Gamification?.message ||
            "Transaction details of coins earned and spent"}`}
        </h2>

        {data?.myorders && data?.myorders.length > 0 && (
          <TableContainer
            component={Paper}
            className={classes.tableContainerStyle}
          >
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell align="left" style={{ fontWeight: "700" }}>
                    {`${userDetails?.languagejson?.Gamification?.Date ||
                      "Date"}`}
                  </StyledTableCell>
                  <StyledTableCell align="left" style={{ fontWeight: "700" }}>
                    {`${userDetails?.languagejson?.Gamification
                      ?.Redeem_Rewards || "Redeem Rewards"}`}
                  </StyledTableCell>
                  <StyledTableCell align="left" style={{ fontWeight: "700" }}>
                    {config?.aws_org_id?.toLocaleLowerCase() === "icici" ? "Points Spent":`${userDetails?.languagejson?.Gamification?.Coins_spent ||
                      "Coins Spent"}`}
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.myorders?.map((row) => (
                  <StyledTableRow key={row.rrid}>
                    <StyledTableCell component="th" scope="row">
                      {row.date}
                    </StyledTableCell>
                    <StyledTableCell align="left">{row.rname}</StyledTableCell>
                    <StyledTableCell align="left">{row.points}</StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        {!data.myorders ||
          (data?.myorders.length === 0 && (
            <>
              <div style={{ textAlign: "center" }}>
                <img src={noThingIsHere} alt="logo" className={classes.responsiveImage} />
              </div>
            </>
          ))}
      </Paper>

      <div>
        {/* Dialog 2*/}
        <div>
          <BootstrapDialog
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={open}
            className={classes.mainDialog}
          >
            <BootstrapDialogTitle
              id="customized-dialog-title"
              onClose={handleClose}
            >
              <h1>{`${userDetails?.languagejson?.Gamification?.Confirmation ||
                "Confirmation"}`}</h1>
            </BootstrapDialogTitle>
            <DialogContent>
              <Typography gutterBottom>
                <p style={{ fontSize: "1.5rem", marginBottom: "5rem" }}>
                  {`${userDetails?.languagejson?.Gamification
                    ?.do_you_want_to_redeem ||
                    "Do you want to redeem the"}`}{" "}
                  {myRedeem?.rname}?
                </p>
              </Typography>
            </DialogContent>
            <DialogActions>
              <div className={classes.buttonSection}>
                <Button
                  variant="outlined"
                  className={classes.secondaryButton}
                  onClick={handleClose}
                >
                  {" "}
                  {`${userDetails?.languagejson?.Gamification?.No_Cancel ||
                    "No, Cancle"}`}
                </Button>
              </div>
              <div className={classes.buttonSection}>
                <Button variant="contained" className={classes.button}>
                  {`${userDetails?.languagejson?.Gamification?.Yes_Continue ||
                    "Yes, Continue"}`}
                </Button>
              </div>
            </DialogActions>
          </BootstrapDialog>
        </div>
      </div>
    </>
  );
}
