import React, { useContext, useState, useEffect } from "react";
import { API } from "aws-amplify";
import { awsSignIn } from "../../redux/auth/authSlice";
import PersonOutlineOutlinedIcon from "@material-ui/icons/PersonOutlineOutlined";
import { CircularProgress, Backdrop, Box, makeStyles } from "@material-ui/core";
import { CustomButton } from "../CustomButton/CustomButton";
import {
  inputBoxStyle,
  inputIconStyle,
  displayCenter,
} from "./SignInAndSignUp";
import { useDispatch} from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import CustomInput from "../CustomInput/CustomInput";
import "react-phone-number-input/style.css";
import { useForm } from "../../Hooks/useForm";
import logincss from "../../pages/Login/Login.module.scss";
import { motion } from "framer-motion";
import icicivalidate from "./SignInSignUpValidationRules/SignInValidationRulesIcici";
import cognitoValidation from "./SignInSignUpValidationRules/SignInCognitoValidationRules";
import { HeadingPrimary } from "../Styles/Heading";
import "./phoneInput.styles.css";
import config from "../../aws-exports";
import { useQueryParams } from "../../Hooks/useQueryParams";
import { languageChange } from "../../api/api";
import crypto from "crypto";
import { get_iciciuserinfo } from "../../api/api";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: "2rem",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    height: "500px",
    width: "600px",
    borderRadius: "4px",
    border: "none",
    padding: theme.spacing(2, 4, 3),
  },
  logoImage: {
    width: "200px",
    "@media (min-width: 760px)": {
      width: "45%",
    },
  },
}));
const SignInIcici = () => {
  const history = useHistory();
  const query = useQueryParams();
  const location = useLocation();
  const dispatch = useDispatch();
  const classes = useStyles();
  const {
    values,
    handleChange,
  } = useForm({ showPassword: false });

  const [signInForm, setSignInForm] = useState(false);
  const [errors, setErrors] = useState({});
  const [btnLoading, setBtnLoading] = useState(false);
  const [loginerror, setLoginerror] = useState("");

  useEffect(() => {
    checkUserLogin();
  }, []);
  async function checkUserLogin() {
    let q;
    if (query.get("q")) {
      q = query.get("q");
    } else {
      const params = new URLSearchParams(window.location.search);
      q = params.get("q");
      if (!q) {
        const hashParams = new URLSearchParams(window.location.hash.slice(1));
        q = hashParams.get("q");
      }
    }
    if (q) {
      let error = {};

      let icici_userinfo = await get_iciciuserinfo(config.schema, q);

      if (icici_userinfo.body.length <= 0) {
        error.email = "User is disabled";
        setSignInForm(true);
        setErrors(error);
        history.push("/login");
      } else {
        if (icici_userinfo.body[0].uid == sessionStorage.getItem("Emp_id")) {
          let userdata = {};
          userdata.emailid = icici_userinfo.body[0].emailid;
          userdata.uid = icici_userinfo.body[0].uid;
          userdata.location = {};
          userdata.themeDark = "light";
          userdata.location.from = "/home";
          if (!icici_userinfo.body[0].locale) {
            userdata.locale = "Default Team";
          }
          userdata.schema = config.schema;
          await getUserDetails(userdata);
        } else {
          setSignInForm(true);
          history.push("/login");
        }
      }
    } else {
      setSignInForm(true);
      history.push("/login");
    }
  }
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (Object.keys(icicivalidate(values)).length !== 0) {
      setErrors(icicivalidate(values));
      return;
    } else {
      setBtnLoading(true);
      try {
        let userData = {};
        userData.username = values.username;
        const aesKey = "WpMHEF55B93Jirh1Wd5OlCOITK/flJWZThYSsLWz9v4=";
        let body = JSON.stringify({
          UID: "51e709b3-ce19-4af7-b51e-810dba8a62d2",
          employeeID: `${values.username}`,
          ReqID: "",
        });
        sessionStorage.setItem("Emp_id", values.username);
        let session_empid = sessionStorage.getItem("Emp_id");
        let encrypteddata = await encrypt(body, aesKey);
        const loginUrl = `https://hr.iciciprulife.com/AuthIpru/lxp/login/?q=${encrypteddata}`;
        window.location.href = loginUrl;
        //getting parametres from redirected url
           } catch (error) {
        setBtnLoading(false);
        setErrors(cognitoValidation(error));
      }
    }
  };

  // Encryption function
  async function encrypt(body, aesKey) {
    try {
      const key = Buffer.from(aesKey, "base64");
      const iv = crypto.randomBytes(16);
      const cipher = crypto.createCipheriv("aes-256-cbc", key, iv);
      let encryptedData = cipher.update(body, "utf8", "base64");
      encryptedData += cipher.final("base64");
      const ivAndEncrypted = Buffer.concat([
        iv,
        Buffer.from(encryptedData, "base64"),
      ]);
      const base64Encrypted = ivAndEncrypted.toString("base64");
      const urlEncodedEncrypted = encodeURIComponent(base64Encrypted);
      return urlEncodedEncrypted;
    } catch (err) {
      //console.log("Error in encyption : ", err);
    }
  }

  const getUserDetails = async (userdata) => {
    try {
      let authkey = localStorage.getItem("accessToken");
      const tokenrequestBody = {
        client_key: userdata.emailid,
        client_secret: config.secretKeyAfter,
        schema: config.schema,
      };
      const tokenjsonString = JSON.stringify(tokenrequestBody);
      const tokenbase64EncodedString = btoa(tokenjsonString);
      const tokenreportInfo = `/getUserToken?json=${encodeURIComponent(
        tokenbase64EncodedString
      )}`;
      const tokenresponse = await API.get(
        config.aws_cloud_logic_custom_name,
        tokenreportInfo,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: authkey,
          },
        }
      );
      userdata.accessToken = tokenresponse.accessToken;
      userdata.refreshToken = tokenresponse.refreshToken;
      userdata.beforeAuth = authkey;
      localStorage.setItem(
        "after_logIn_accessToken",
        tokenresponse?.accessToken
      );
      window.after_logIn_accessToken = tokenresponse?.accessToken;
      const { cid, emailid, uid, email, schema } = userdata;
      const requestBody = { cid, emailid, uid, email, schema };
      const jsonString = JSON.stringify(requestBody);
      const base64EncodedString = btoa(jsonString);
      const reportInfo = `/getUserDetails?json=${encodeURIComponent(
        base64EncodedString
      )}`;
      const response = await API.get(
        config.aws_cloud_logic_custom_name,
        reportInfo,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: tokenresponse.accessToken,
          },
        }
      );
      if (response && response.body[0] && response.body[0].enable) {
        userdata.uData = response.body[0];
        await analyticsWebApp(userdata);
      }
    } catch (err) {
      history.push("/login");
    }
  };
  async function analyticsWebApp(userDetails) {
    const jsonString = {
      oid: config.aws_org_id,
      eventtype: "AuthenticatedViaCognito",

      email: userDetails.username,
      name: userDetails.name,
      emailid: userDetails.email,
      tenant: userDetails.uData?.oid,
      ur_id: userDetails.uData?.ur_id,
      schema: config.schema,
    };

    const nameModify = JSON.stringify(jsonString);
    const base64EncodedString = btoa(nameModify);
    const bodyParam = {
      body: {
        json: base64EncodedString,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: userDetails?.accessToken,
      },
    };
    try {
      const res = await API.post(
        config.aws_cloud_logic_custom_name,
        "/analyticsWebApp",
        bodyParam
      );
      let lang = await languageChange(userDetails, userDetails.uData?.lang);
      userDetails.languagejson = lang?.languagejson;
      userDetails.themeDark = "light";
      dispatch(awsSignIn(userDetails));
      setBtnLoading(false);
      let id = query.get("id");
      if (location.pathname === "/course" && id) {
        history.push(`/course?id=${id}`);
      } else if (location.pathname === "/content" && id) {
        history.push(`/content?id=${id}`);
      } else {
        history.replace({
          pathname: "/home",
          state: { poster: true },
        });
      }
    } catch (err) {
      history.push("/home");
    }
  }


  return (
    <motion.div
      className={
        config?.loginImageHide
          ? logincss.loginformdivColor
          : logincss.loginformdiv
      }
      style={{
        height: "100vh",
        display: "grid",
        placeItems: "center",
      }}
    >
      {!signInForm ? (
        <Backdrop className={classes.backdrop} open={true}>
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (
        <motion.div
          className={logincss.loignformdiv3}
          style={{
            maxWidth: "750px",
          }}
          role="main"
          aria-label="Login Section"
          initial={{ x: "-100vw", opacity: 1 }}
          animate={{ x: 0, opacity: 1 }}
          transition={{
            delay: 1,
            x: { type: "spring", stiffness: 100 },
            default: { duration: 2 },
          }}
        >
          <Box
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <img
              src={`https://${
                config.DOMAIN
              }/${config.aws_org_id?.toLowerCase()}-resources/images/org-images/logo-dark.png`}
              alt="logo"
              height="100px"
              tabIndex={1}
            />
          </Box>

          <React.Fragment>
            <Box style={{ textAlign: "center" }}>
              <HeadingPrimary tabIndex={2}>
                <h3>Login to your account</h3>
              </HeadingPrimary>
            </Box>
            <form
              onSubmit={handleSubmit}
              noValidate
              autoComplete="off"
              style={displayCenter}
            >
              <div style={inputBoxStyle}>
                <CustomInput
                  id="input-username"
                  placeholder="Employee Id"
                  type="email"
                  value={values.username || ""}
                  error={errors.email}
                  onChange={handleChange("username")}
                  style={{ fontFamily: "myfontregular" }}
                >
                  <PersonOutlineOutlinedIcon style={inputIconStyle} />
                </CustomInput>
              </div>

              <div style={{ textAlign: "center" }}>
                {btnLoading ? (
                  <CustomButton disabled loading="true">
                    <CircularProgress color="inherit" size={10} />
                  </CustomButton>
                ) : (
                  <CustomButton
                    type="submit"
                    primary
                    className="btnlogin"
                    style={{ fontFamily: `myfontregular` }}
                  >
                    Login
                  </CustomButton>
                )}
              </div>
            </form>
          </React.Fragment>
        </motion.div>
      )}
    </motion.div>
  );
};
export default SignInIcici;
