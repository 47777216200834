import React, { useState } from "react";
import { StyledCard } from "./SakshamLiveCard.styles";
import { ReactComponent as LPIcon } from "../../../assets/svg/LP_icon.svg";
import {
  Bookmark,
  BookmarkBorderOutlined,
  StarRounded,
} from "@material-ui/icons";
import config from "../../../aws-exports";
import { API } from "aws-amplify";
import { useSelector, useDispatch } from "react-redux";
import { authData, awsSignIn } from "../../../redux/auth/authSlice";
import { Link, useHistory } from "react-router-dom";
import teams from "../../../assets/teams.png";
import ts from "../../../assets/timeicon.png";

export default function SakshamLiveCard({
  title,
  start,
  end,
  onlineMeeting,
  organizer,
}) {
  const [imgError, setImgError] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const userDetails = useSelector(authData);
  const handleImageError = () => {
    setImgError(true);
  };
  const divColorCodes = [
    "#E2D2FE!important",
    "#FDE1AB!important",
    "#CCF0BF!important",
    "#FFD27E!important",
    "#BAE5F4!important",
    "#EECFCF!important",
  ];

  const formatDateTime = (dateTimeStr) => {
    const dateObj = new Date(dateTimeStr);

    const formattedDate = new Intl.DateTimeFormat("en-GB", {
      day: "numeric",
      month: "long",
      year: "numeric",
    }).format(dateObj);

    const formattedTime = new Intl.DateTimeFormat("en-US", {
      hour: "numeric",
      minute: "2-digit",
      hour12: true,
    }).format(dateObj);

    // Convert day number to ordinal format (e.g., 12 -> 12th)
    const day = dateObj.getDate();
    const suffix = ["th", "st", "nd", "rd"][
      day % 10 > 3 || Math.floor((day % 100) / 10) === 1 ? 0 : day % 10
    ];

    return `${day}${suffix} ${formattedDate.split(" ")[1]}, ${
      formattedDate.split(" ")[2]
    } | ${formattedTime}`;
  };

  const handleLocationSaksham = () => {
    let sData = { ...userDetails };

    sData.saksham = {};
    sData.saksham.onlineMeeting = onlineMeeting;
    sData.saksham.organizer = organizer;
    sData.saksham.start = start;
    sData.saksham.end = end;
    sData.saksham.title = title;

    dispatch(awsSignIn(sData));
    history.push(`/sakshamlivedetails`);
  };

  return (
    <StyledCard
      style={{ color: "black", height: "22rem" }}
      //color={divColorCodes[2 % divColorCodes.length]}
      onClick={handleLocationSaksham}
    >
      <div
        className="card-actions"
        style={{ height: "6rem", position: "relative" }}
      >
        <div
          style={{
            position: "absolute",
            top: "10px", // Adjust as needed
            left: "10px", // Adjust as needed
            width: "36px",
            height: "34px",
          }}
        >
          <img src={teams} alt="Teams Logo" />
        </div>
        <div style={{ fontWeight: 600, marginTop: "5rem" }}>{title}</div>
        <div className="card-actions--footer">
          <div className="card-actions--footer-time">
            <img src={ts} alt="Teams Logo" />
          </div>
          <div>
            <div>Start : {formatDateTime(start?.dateTime)}</div>
            <div>End : {formatDateTime(end?.dateTime)} </div>
          </div>
        </div>
      </div>
    </StyledCard>
  );
}
