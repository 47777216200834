import React, { useState, useEffect } from "react";
import {
  Box,
  Card,
  CardContent,
  Typography,
  Chip,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  IconButton,
  Modal,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { API } from "aws-amplify";
import { useSelector } from "react-redux";
import { authData } from "../../redux/auth/authSlice";
import config from "../../aws-exports";
import axios from "axios";
import Swal from "sweetalert2";
import Nothing_here_yet from "../../assets/Nothing here yet.gif";

function AllAssignments() {
  const userDetails = useSelector(authData);
  const [loading, setLoading] = useState(false);
  const [assignments, setAssignments] = useState([]);
  const [selectedAssignment, setSelectedAssignment] = useState(null);
  const [selectedFile, setSelectedFile] = useState({});
  const [isFileViewerOpen, setIsFileViewerOpen] = useState(false);

  const [userAnswers, setUserAnswers] = useState({});
  const [userFiles, setUserFiles] = useState({});
  const [submitButton, setSubmitButton] = useState(true);
  useEffect(() => {
    getAssignments();
  }, []);
  const viewFile = async (filePath, filetype, question) => {
    
    setSelectedFile({ filePath, filetype, question });
    setIsFileViewerOpen(true);
  };

  const closeFileViewer = () => {
    setSelectedFile({});
    setIsFileViewerOpen(false);
  };
  async function getAssignments() {
    setLoading(true);
    const requestBody = {
      schema: config.SCHEMA_NAME,
      type: "user",
      oid: userDetails?.uData?.oid,
      ur_id: userDetails?.uData?.ur_id,
      gid:userDetails?.uData?.gid ?? []
    };

    try {
      const jsonString = JSON.stringify(requestBody);
      const base64EncodedString = btoa(jsonString);
      const reportInfo = `/getassignments?json=${encodeURIComponent(
        base64EncodedString
      )}`;
      const response = await API.get(
        config.aws_cloud_logic_custom_name,
        reportInfo,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: userDetails?.accessToken,
          },
        }
      );

      if (!Array.isArray(response.res)) {
        throw new Error("Invalid response format");
      }

      setAssignments(response.res);
    } catch (err) {
      console.error("Error fetching assignments:", err);
    } finally {
      setLoading(false);
    }
  }

  function handleAnswerChange(index, value) {
    setUserAnswers((prev) => ({ ...prev, [index]: value }));
  }

  async function handleFileChange(index, file) {
    try {
      setUserFiles((prev) => ({
        ...prev,
        [index]: {
          file: file,
          type: file?.type || null, // Store file type with the file
        },
      }));
    } catch (error) {
      console.error("File upload failed:", error);
    }
  }

  async function uploadFileToS3(files) {
    const uploadedFileUrls = {};

    for (const [index, filedata] of Object.entries(files)) {
      if (filedata) {
        let filedatainfo = filedata.file;

        const fileExtension = filedatainfo.name.split(".").pop(); // Extract file extension
        const fileName = `${index}.${fileExtension}`; // Create file name like "0.pdf", "1.jpg", etc.

        try {
          const body = {
            filetype: filedatainfo.type,
            filename: fileName,
            folder: `assignments/${userDetails?.uData?.ur_id}/${selectedAssignment.aid}`, // Adjust folder as needed
            oid: config.aws_org_id,
            type: "assignments",
            ur_id: userDetails?.uData?.ur_id,
          };

          const jsonString = JSON.stringify(body);
          const base64EncodedString = btoa(jsonString);
          const reportInfo = `/getPreSignedURL?json=${encodeURIComponent(
            base64EncodedString
          )}`;

          const preSignedData = await API.get(
            config.aws_cloud_logic_custom_name,
            reportInfo,
            {
              headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                Authorization: userDetails?.accessToken,
              },
            }
          );

          const response = await axios.put(preSignedData, filedatainfo, {
            headers: {
              "Content-Type": filedatainfo.type,
              "Cache-Control": "no-cache no-store must-revalidate",
            },
          });

          uploadedFileUrls[index] = {
            url: preSignedData.split("?")[0],
            type: fileExtension, // Store type with the URL
          }; // Store the S3 URL under the question index
        } catch (error) {
          console.error("Error uploading file to S3:", error);
          throw error;
        }
      }
    }

    return uploadedFileUrls; // Return object of S3 URLs
  }

  async function handleSubmitAnswers() {
    try {
      setSubmitButton(false);
      const responseArray = [];

      // Process each question
      for (const [index, question] of selectedAssignment.questions.entries()) {
        const userAnswer = userAnswers[index] || "";
        const userFile = userFiles[index]; // Get the file for this question

        // Add question response to the array
        responseArray.push({
          question: question,
          uanswer: userAnswer,
          file: userFile || null, // Will be null if no file is uploaded
          filetype: userFile?.type || null, // Add file type here
        });
      }

      // Upload files to S3 and get their URLs
      const uploadedFileUrls = await uploadFileToS3(userFiles);
      let sub_date = new Date().toLocaleDateString("en-CA");

      // Prepare the request body for /submituserprogress
      const requestBody = {
        type: "user",
        ur_id: userDetails?.uData?.ur_id,
        aid: selectedAssignment.aid,
        cgry_id: selectedAssignment?.cgry_id,
        an: selectedAssignment.an,
        st_id: userDetails.uData.st_id,
        sub_date: sub_date,
        response: responseArray.map((response, index) => ({
          ...response,
          path: uploadedFileUrls[index]?.url || null,
          filetype: uploadedFileUrls[index]?.type || null,
        })),
      };
      const jsonString = JSON.stringify(requestBody);
      const bodyencoded = btoa(jsonString);
      const bodyParam = {
        body: {
          json: bodyencoded,
        },
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: userDetails?.accessToken,
        },
      };

      // Call the /submituserprogress API
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        "/submituserprogress",
        bodyParam
      );
      if (response.statusCode == 200) {
        Swal.fire({
          title: "Good job!",
          text: "You submitted the Assignment",
          icon: "success",
        });
      }
      getAssignments();
      setSubmitButton(true);
      setSelectedAssignment(null);
      setUserAnswers({});
      setUserFiles({});
    } catch (err) {
      console.error("Error submitting answers:", err);
    }
  }
  const formatDate = (date) => {
    if (!date) return ""; // Handle empty dates
    const d = new Date(date);
    const year = d.getFullYear();
    const month = String(d.getMonth() + 1).padStart(2, "0"); // Months are 0-based
    const day = String(d.getDate()).padStart(2, "0");
    return `${day}-${month}-${year}`;
  };
  const isAssignmentExpired = (date) => {
    const currentDate = new Date();
    const submissionDateObj = new Date(date);
    let isexpired = currentDate < submissionDateObj ? false : true;
    return isexpired;
  };
  return (
   <>
    <Box sx={{ padding: "2rem", fontFamily: "nunito, sans-serif" }}>
      {/* Assignments Section */}
      <Typography variant="h4" fontWeight="2rem" gutterBottom>
        Assignments
      </Typography>
      {loading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "200px",
          }}
        >
          <CircularProgress />
        </Box>
      ) : assignments.length > 0 ? (
        <Box sx={{ display: "flex", gap: 2, flexWrap: "wrap" }}>
          {assignments.map((assignment) => {
            const isCompleted = assignment?.userprogress?.submitted;
            return (
              <Card
                key={assignment.aid}
                sx={{
                  width: "250px",
                  borderRadius: 2,
                  boxShadow: 2,
                  cursor: "pointer",
                }}
                onClick={() => {
                  if (assignment.userprogress?.submitted) {
                    setSelectedAssignment(assignment);
                  } else {
                    if (!isAssignmentExpired(assignment?.asub_date)) {
                      setSelectedAssignment(assignment);
                    } else {
                      Swal.fire({
                        icon: "error",
                        title: "Submission Date Ended",
                        text:
                          "The submission date for this assignment has ended.",
                      });
                    }
                  }
                }}
              >
                <CardContent>
                  <Typography variant="subtitle3" fontWeight="bold">
                    📄 {assignment.an}
                  </Typography>
                  <Typography color="text.secondary">
                    Due Date: {formatDate(assignment?.asub_date)}
                  </Typography>
                  <Chip
                    label={
                      assignment?.userprogress?.submitted
                        ? "Completed"
                        : isAssignmentExpired(assignment?.asub_date)
                        ? "Expired"
                        : "Pending"
                    }
                    color={
                      assignment.userprogress?.submitted
                        ? "success"
                        : isAssignmentExpired(assignment?.asub_date)
                        ? "error"
                        : "warning"
                    }
                    sx={{ marginTop: 1 ,cursor:"pointer"}}
                  />
                  {/* Display Score if available */}
                  {assignment?.userprogress?.scores !== null && (
                    <Typography
                      variant="body1"
                      sx={{ mt: 1, color: "green", fontWeight: "bold" }}
                    >
                      Score: {assignment?.userprogress?.scores}
                    </Typography>
                  )}
                </CardContent>
              </Card>
            );
          })}
        </Box>
      ) : (
        <>
          <img
            src={Nothing_here_yet}
            style={{ alignItems: "center", height: "20rem" }}
            alt="no data available"
          />
        </>
      )}

      {/* Scoreboard Section */}
      <Typography
        variant="h4"
        fontWeight="2rem"
        gutterBottom
        sx={{ marginTop: 4 }}
      >
        Scoreboard
      </Typography>
      {assignments.length > 0 ? (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow sx={{ backgroundColor: "#0D47A1", color: "white" }}>
                <TableCell
                  sx={{
                    color: "white",
                    fontWeight: "bold",
                    fontSize: "1.5rem",
                  }}
                >
                  Assignment Name
                </TableCell>
                <TableCell sx={{ color: "white", fontWeight: "bold" ,fontSize: "1.5rem"}}>
                  Scores
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {assignments.map((assignment) => (
                <TableRow
                  key={assignment.aid}
                  sx={{
                    backgroundColor:assignment.aid % 2 === 0 ? "#F5F5F5" : "white",
                    fontSize: "1.5rem"
                  }}
                >
                  <TableCell sx={{fontSize: "1rem",fontWeight:"bold"}}>{assignment.an}</TableCell>
                  <TableCell sx={{fontSize: "1rem",fontWeight:"bold"}}>
                    {assignment?.userprogress?.scores !== null
                      ? assignment?.userprogress?.scores
                      : "Not Graded"}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <>
          <img
            src={Nothing_here_yet}
            style={{ alignItems: "center", height: "20rem" }}
            alt="no data available"
          />
        </>
      )}

      {/* Popup Modal */}
      <Modal
        open={Boolean(selectedAssignment)}
        onClose={() => setSelectedAssignment(null)}
      >
        <Paper
          sx={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "60%",
            maxHeight: "80vh", // Set a maximum height for the modal
            overflow: "auto",
            p: 3,
            margin: "1rem",
            boxShadow: 3,
            borderRadius: 2,
            zIndex: 1000,
          }}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mb={2}
          >
            <Typography variant="h4">
              {selectedAssignment?.an || "Assignment"}
            </Typography>
            <IconButton onClick={() => setSelectedAssignment(null)}>
              <CloseIcon />
            </IconButton>
          </Box>

          {/* Display Score if available */}
          {selectedAssignment && selectedAssignment?.userprogress?.scores && (
            <Typography
              variant="body2"
              sx={{ mb: 2, color: "green", fontWeight: "bold",fontSize:"1rem" }}
            >
              Score: {selectedAssignment.userprogress.scores}
            </Typography>
          )}

          {selectedAssignment?.questions &&
          selectedAssignment.questions.length > 0 ? (
            selectedAssignment.questions.map((question, index) => {
              const userProgress = selectedAssignment.userprogress;
              const isCompleted = userProgress?.submitted;
              const userResponse = isCompleted ? userProgress.aresponse[index]: null;
              const userAnswer = isCompleted ? userResponse.uanswer : userAnswers[index] || "";
              const userFile = isCompleted ? userResponse.path : userFiles[index] || null;
               const userFileType = isCompleted ? userResponse.filetype : userFiles[index] || null; 

              return (
                <Paper key={index} variant="outlined" sx={{ p: 2, my: 2 }}>
                  <Typography variant="body3" fontWeight="2rem">
                    Q{index + 1}. {question}
                  </Typography>

                  {isCompleted ? (
                    <>
                      <Typography
                        variant="body3"
                        sx={{ mt: 1, display: "flex" }}
                      >
                        Ans: {userAnswer || "No answer available"}
                      </Typography>
                      {userFile && (
                        <Typography
                          variant="h6"
                          sx={{ color: "blue", mt: 1, cursor: "pointer" }}
                          onClick={() =>
                           viewFile(userFile,userFileType,question)
                          }
                        >
                          View Attachment
                        </Typography>
                      )}
                    </>
                  ) : (
                    <>
                      <TextField
                        fullWidth
                        variant="outlined"
                        placeholder="Type your answer..."
                        value={userAnswer}
                        onChange={(e) =>
                          handleAnswerChange(index, e.target.value)
                        }
                        sx={{
                          mt: 1,
                          "& .MuiInputBase-input": {
                            fontSize: "1.3rem", // Adjust the font size as needed
                          },
                        }}
                      />
                      <input
                        type="file"
                        accept=".pdf,.doc,.docx,.jpg,.jpeg,.png"
                        onChange={(e) =>
                          handleFileChange(index, e.target.files[0])
                        }
                        style={{ marginTop: "10px" }}
                      />
                      {userFile && (
                        <Typography variant="body2" sx={{ mt: 1 }}>
                          Selected File: {userFile.name}
                        </Typography>
                      )}
                    </>
                  )}
                </Paper>
              );
            })
          ) : (
            <Typography>No questions available</Typography>
          )}

          <Box mt={2} textAlign="right">
            {!selectedAssignment?.userprogress?.submitted && (
              <Button
                onClick={handleSubmitAnswers}
                color="primary"
                variant="contained"
                disabled={!submitButton}
              >
                Submit
              </Button>
            )}
            <Button
              onClick={() => setSelectedAssignment(null)}
              color="secondary"
              sx={{ ml: 2 }}
            >
              Close
            </Button>
          </Box>
        </Paper>
      </Modal>
    </Box>
    <Modal open={isFileViewerOpen} onClose={closeFileViewer}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "80%",
            height: "80%",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 2,
            borderRadius: 2,
          }}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mb={2}
          >
            <div>
              <Typography variant="h6">Q : {selectedFile.question}</Typography>
              <Typography variant="h6">Ans :</Typography>
            </div>
            <IconButton onClick={closeFileViewer}>
              <CloseIcon />
            </IconButton>
          </Box>

          {selectedFile?.filetype === "pdf" ? (
            <iframe
              src={selectedFile.filePath}
              width="100%"
              height="80%"
              style={{ border: "none" }}
            />
          ) : selectedFile?.filetype === "docx" ? (
            <iframe
              src={`https://docs.google.com/gview?url=${selectedFile.filePath}&embedded=true`}
              width="100%"
              height="80%"
              style={{ border: "none" }}
            />
          ) : (
            <img
              src={selectedFile.filePath}
              alt="Attachment"
              style={{ maxWidth: "100%", maxHeight: "80%" }}
            />
          )}
        </Box>
      </Modal>
   </>
    
  );
}

export default AllAssignments;
