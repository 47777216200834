import React, { useState } from "react";
import { useSelector } from "react-redux";
import { authData } from "../../redux/auth/authSlice";
import { Skeleton } from "@material-ui/lab";
import API from "@aws-amplify/api";
import config from "../../aws-exports";
import {
  Stack,
  Typography,
  Breadcrumbs,
  Button,
  Box,
  Tab,
  Tabs,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
} from "@mui/material";
import { Link } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { makeStyles } from "@material-ui/core/styles";
import TabContext from "@mui/lab/TabContext";
import TabPanel from "@mui/lab/TabPanel";
import PropTypes from "prop-types";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import CloseIcon from "@mui/icons-material/Close";
import MyStore from "./MyStore";
import MyOrders from "./MyOrders";
import MyCoins from "./MyCoins";
import MyCoin from "../../assets/My Coins.png";
import MyStorepng from "../../assets/My Store.png";
import MyOrderspng from "../../assets/My Orders.png";
import { fetchData } from "../../api/api";
import { useQuery } from "@tanstack/react-query";
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialog-paper": {
    width: "50%",
    maxWidth: "none",
    "@media (max-width: 750px)": {
      width: "90%",
    },
  },
  "& .MuiDialogContent-root": {
    padding: "1rem 2rem",
  },
  "& .MuiDialogTitle-root": {
    padding: "1rem 1rem 1rem 2rem",
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));
function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;
  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}
BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};
function MyStoreCoins() {
  const userDetails = useSelector(authData);
  const { isLoading, error, data, refetch } = useQuery(
    ["getmdata"],
    async () => {
      // var body = {
      //     ur_id: userDetails?.uData?.ur_id,
      //   },
      //   apiname = "/getMystoreAndMyCoins";
      // const response = await fetchData(body, apiname);
        const requestBody = {
          schema:`${config.schema}`,
          ur_id: userDetails?.uData?.ur_id,
        };
        const jsonString = JSON.stringify(requestBody);
        const base64EncodedString = btoa(jsonString);
        const reportInfo = `/getMystoreAndMyCoins?json=${encodeURIComponent(
          base64EncodedString
        )}`;
        const response = await API.get(
          config.aws_cloud_logic_custom_name,
          reportInfo,
          {
            headers: {
              "Content-Type": "application/json",
        Accept: "application/json",	
        Authorization: userDetails?.accessToken
            },
          }
        );
      return response;
    }
  );
  const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      marginTop: "2rem",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    responsive: {
      [theme.breakpoints.up(1300)]: {
        fontSize: "1rem",
      },
    },
    mainContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "start",
      "@media (max-width: 550px)": {
        marginBottom: "1rem",
      },
    },
    loaderSkeleton: {
      width: "200px",
      height: "3rem",
      "@media (max-width: 750px)": {
        width: "200px",
      },
    },
    mainSection: {
      padding: "2rem!important",
      "@media (max-width: 450px)": {
        padding: "1rem!important",
      },
    },
    loaderSkeletonTabs: {
      width: "90vw",
      height: "50rem",
    },
    LPMainContainer: {
      width: "100%",
      boxShadow:
        userDetails.themeDark === "dark"
          ? "none !important"
          : "0 0 10px 0 hsla(0,0%,40%,.3607843137)",
      backgroundColor:
        userDetails.themeDark === "dark"
          ? "#292F3B !important"
          : theme.palette.background.paper,
    },
    paper: {
      paddingBottom: "2rem",
      marginBottom: "2rem",
      boxShadow:
        "0px 2px 14px -1px rgb(0 0 0 / 10%), 0px 4px 5px 0px rgb(0 0 0 / 4%), 0px 1px 0px 0px rgb(0 0 0 / 2%)!important",
    },
    buttonSection: {
      textAlign: "center",
      margin: "1rem",
      width: "100%",
    },
    button: {
      backgroundColor: "var(--color-button-background)!important",
      fontSize: "1.5rem!important",
      textTransform: "capitalize!important",
      fontFamily: `myfontregular!important`,
    },
    tabButton: {
      textTransform: "capitalize!important",
      fontSize: "2rem!important",
      paddingTop: "0!important",
      fontFamily: `myfontregular!important`,
      fontWeight: "800!important",
      alignItems: "start!important",
      paddingLeft: "5rem!important",
      textAlign: "left!important",
    },
    tabIcon: {
      width: "3rem",
      position: "relative",
      left: "-35px",
      top: "27px",
      margin: "0!important",
    },
    mainDialog: {
      "& > div > div": {
        margin: 0,
        backgroundColor:
          userDetails.themeDark === "dark"
            ? "#272C38 !important"
            : theme.palette.background.paper,
        color:
          userDetails.themeDark === "dark" ? "white" : "#272C38 !important",
        "& > h2 > button ": {
          color:
            userDetails.themeDark === "dark"
              ? "white !important"
              : "#272C38 !important",
        },
      },
    },
    breadcrumbNameStyle: {
      fontSize: "1.5rem!important",
      color:
        userDetails.themeDark === "dark"
          ? "white !important"
          : "black !important",
      fontFamily: `myfontregular!important`,
    },
  }));
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState("3");
  const handleChange = (event, newValue) => {
    data?.rewards?.forEach((fritem, index) => {
      data.rewards[index].userqty = false;
      data.rewards[index].qty = 0;
    });
    setValue(newValue);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const breadcrumbs = [
    <Link
      underline="hover"
      key="1"
      color="inherit"
      to={"/home"}
      style={{ fontSize: "1.5rem", cursor: "pointer", textDecoration: "none" }}
    >
      {`${userDetails?.languagejson?.Home_Page?.Home || "Home"}`}
    </Link>,
    <Typography
      key="3"
      color="text.primary"
      className={classes.breadcrumbNameStyle}
    >
      {config.aws_org_id.toLocaleLowerCase() === "icici" ? "My Points":`${userDetails?.languagejson?.Gamification?.My_Coins || "My Coins"}`}
    </Typography>,
  ];
  return (
    <>
      <Stack spacing={2} style={{ marginBottom: "2rem" }}>
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="large" style={{color:"var(--color-primary)"}} />}
          aria-label="breadcrumb"
        >
          {breadcrumbs}
        </Breadcrumbs>
      </Stack>
      <div className="flex-between">
        <div className={classes.mainContainer}>
          <h3 style={{ fontWeight: "600" }} className="responsive">
            {isLoading ? (
              <Skeleton className={classes.loaderSkeleton} />
            ) : (
              <>
                {" "}
                {config.aws_org_id.toLowerCase() !== "icici" ? "My Points ":`${userDetails?.languagejson?.Gamification?.My_Coins ||
                  "My Coins"}`}
              </>
            )}
          </h3>
        </div>
      </div>
      {isLoading ? (
        <Skeleton className={classes.loaderSkeletonTabs} />
      ) : (
        <div className={classes.root}>
          <div className={classes.LPMainContainer}>
            <TabContext value={value}>
              <Box
                sx={{
                  borderBottom: 1,
                  backgroundColor: "transparent",
                  borderColor: "divider",
                  overflow: "auto!important",
                  "& > div": {
                    "@media (max-width: 768px)": {
                      width: "56rem",
                    },
                    "& > div > div > .Mui-selected": {
                      background:
                        userDetails.themeDark === "dark"
                          ? "linear-gradient(1deg, white, #d4eaff) !important"
                          : "linear-gradient(1deg, #d4eaff, transparent)",
                      color: "var(--color-button-background)!important",
                    },
                  },
                }}
              >
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                  indicatorColor="primary"
                  textColor="inherit"
                  variant="fullWidth"
                >
                  <Tab
                    label={config?.aws_org_id?.toLocaleLowerCase() === "icici" ? "Points" : `${userDetails?.languagejson?.Leaderboard
                      ?.coins || "Coins"}`}
                    value="3"
                    className={classes.tabButton}
                    icon={
                      <img
                        src={MyCoin}
                        alt="Balance Available Coins"
                        title={config.aws_org_id.toLowerCase() === "icici" ? "Balence Available Points ": "Balance Available Coins"}
                        className={classes.tabIcon}
                      />
                    }
                  />
                  <Tab
                    label={`${userDetails?.languagejson?.Gamification?.Redeem ||
                      "Redeem"}`}
                    value="1"
                    className={classes.tabButton}
                    icon={
                      <img
                        src={MyStorepng}
                        alt="Balance Available Coins"
                        title={config?.aws_org_id?.toLocaleLowerCase() === "icici" ? "Balance Available Points": "Balance Available Coins"}
                        className={classes.tabIcon}
                      />
                    }
                  />
                  <Tab
                    label={`${userDetails?.languagejson?.Gamification
                      ?.My_orders || "My Orders"}`}
                    value="2"
                    className={classes.tabButton}
                    icon={
                      <img
                        src={MyOrderspng}
                        alt="Balance Available Coins"
                        title={config?.aws_org_id?.toLocaleLowerCase() === "icici" ? "Balance Available Points" :"Balance Available Coins"}
                        className={classes.tabIcon}
                      />
                    }
                  />
                </Tabs>
              </Box>
              {/* Tab 1 */}
              <TabPanel value="1" className={classes.mainSection}>
                {!isLoading && (
                  <MyStore
                    userDetails={userDetails}
                    data={data}
                    refetch={refetch}
                    isLoading={isLoading}
                  />
                )}
              </TabPanel>
              {/* Tab 2 */}
              <TabPanel value="2" className={classes.mainSection}>
                <MyOrders
                  userDetails={userDetails}
                  data={data}
                  refetch={refetch}
                />
              </TabPanel>
              {/* Tab 3 */}
              <TabPanel value="3" className={classes.mainSection}>
                <MyCoins
                  userDetails={userDetails}
                  data={data}
                  refetch={refetch}
                />
              </TabPanel>
            </TabContext>
            {/* Dialog 1*/}
            <div>
              <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
                className={classes.mainDialog}
              >
                <BootstrapDialogTitle
                  id="customized-dialog-title"
                  onClose={handleClose}
                >
                  {`${userDetails?.languagejson?.Gamification?.Add_Reward ||
                    "Add Reward"}`}
                </BootstrapDialogTitle>
                <DialogContent>
                  <Typography gutterBottom>Liju</Typography>
                </DialogContent>
                <DialogActions>
                  <div className={classes.buttonSection}>
                    <Button variant="contained" className={classes.button}>
                      {`${userDetails?.languagejson?.Edit_Profile?.Save ||
                        "Save"}`}
                    </Button>
                  </div>
                </DialogActions>
              </BootstrapDialog>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
export default MyStoreCoins;
