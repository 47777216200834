import "./assets/scss/reset.scss";
import "./assets/scss/base.scss";
import LandingPage from "./pages/LandingPage/LandingPage";
import SignInAndSignUp from "./components/SignInAndSignUp/SignInAndSignUp";
import "./App.scss";
import React, { useState, useEffect, useRef } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import Course from "./pages/Course/Course";
import RadioObject from "./pages/RadioObject/RadioObject";
import LearningPath from "./pages/Learning/LearningPath";
import Explore from "./pages/Explore/Explore";
import CourseView from "./pages/Course/CourseView/CourseView";
import ExploreViewAll from "./pages/Explore/ExploreViewAll";
import ExploreCourseView from "./pages/Explore/ExploreCourseView";
import ExploreCourseViews from "./pages/Explore/ExploreCourseViews";
import MyProfile from "./pages/MyProfile/MyProfile";
import BookmarkView from "./pages/BookmarkView/BookmarkView";
import Competency from "./pages/Competency/CompetencyUserProfile";
import AllProgramView from "./pages/AllProgramView/AllProgramView";
import ViewAll from "./pages/ViewAll/ViewAll";
import Calender from "./pages/Calender/Calender";
import ContactUs from "./pages/ContactUs/ContactUs";
import SharedObject from "./pages/SharedObject/SharedObject";
import { useSelector } from "react-redux";
import { authData, awsSignOut } from "./redux/auth/authSlice";
import ViewAllCourses from "./pages/ViewAllCourses/ViewAllCourses";
import AllPrograms from "./pages/ViewAll/AllPrograms/AllPrograms";
import MyPrograms from "./pages/ViewAll/MyPrograms/MyPrograms";
import ContinueLearning from "./pages/ViewAll/ContinueLearning/ContinueLearning";
import AllTopics from "./pages/ViewAll/AllTopics/AllTopics";
import { useNavigatorStatus } from "react-navigator-status";
import Layout from "./components/Layout/Layout";
import { AnimatePresence } from "framer-motion";
import { useLocation } from "react-router-dom";
import "../src/font/NunitoSans-Regular.ttf";
import config, { Theme } from "./aws-exports";
import Discussion from "./pages/Discussion/Discussion";
import { ThemeProvider } from "styled-components";
import Login from "./pages/NSELoginFlow/Login";
import SignUp from "./pages/NSELoginFlow/SignUp";
import ConfirmSignUp from "./pages/NSELoginFlow/ConfirmSignUp";
import ConfirmForgotPassword from "./pages/NSELoginFlow/ConfirmForgotPassword";
import ForgotPassword from "./pages/NSELoginFlow/ForgotPassword";
import ResetPassword from "./pages/NSELoginFlow/ResetPassword";
import SignUpONDC from "./pages/ONDCLoginFlow/SignUp";
import ConfirmSignUpONDC from "./pages/ONDCLoginFlow/ConfirmSignUp";
import ForgotPasswordONDC from "./pages/ONDCLoginFlow/ForgotPassword";
import ResetPasswordONDC from "./pages/ONDCLoginFlow/ResetPassword";
import LiveSession from "./pages/LiveSession/LiveSession";
import EditProfile from "./pages/MyProfile/EditProfile/EditProfile";
import Wishlist from "./pages/WishList/Wishlist";
import Leaderboard from "./pages/Leaderboard/Leaderboard";
import MyStoreCoins from "./pages/MyStoreCoins/MyStoreCoins";
import ONDCLandingPage from "./pages/StaticPages/ONDCLandingPage";
import LoginExcelLearn from "./pages/Login/Login";

import LearningAnalytics from "./pages/LearningAnalytics/LearningAnalytics";
import LearnerProgress from "./pages/LearnerProgress/LearnerProgress";
import ExploreTopics from "./pages/Explore/ExploreTopics";
import SocialLearning from "./pages/SocialLearning/SocialLearning";
import ViewAllTopic from "./pages/SocialLearning/ViewAllTopic";
import FollowPeople from "./pages/SocialLearning/FollowPeople";
import SliderInDetails from "./pages/SocialLearning/SliderInDetails";
import TeamsProfile from "./pages/SocialLearning/TeamsProfile";
import OtherUserProfile from "./pages/SocialLearning/OtherUserProfile";
import { useRouteMatch } from "react-router-dom";
import CreatePost from "./pages/SocialLearning/CreatePost/CreatePost";
import MyPost from "./pages/SocialLearning/MyPost";
import TimeSpent from "./components/TimeSpent/TimeSpent";
import LearningPathViewAll from "./pages/ViewAll/LearningPath/LearningPathViewAll";
import MyPeers from "./components/ActiveUsers/MyPeers";
import MyPeersCompare from "./components/ActiveUsers/Compare";
import MyPeersCommonCourses from "./components/ActiveUsers/CommonCourses";
import MyPeersDifferentCourses from "./components/ActiveUsers/DifferentCourses";
import JswFormpage from "./pages/ProfileForms/JswFormpage";
import SignInIcici from "./components/SignInAndSignUp/SignInIcici";
//autologout imports
import { getUserDetails } from "./api/api";
import { Auth } from "aws-amplify";
import Cookies from "js-cookie";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import AllAssignments from "./pages/Assignments/AllAssignments";
import SakshamLive from "./pages/SakshamLive/sakshamLive";
import SakshamLiveDetails from "./pages/SakshamLive/SakshamLiveDetails";

function App() {
  const userAuthStateValue = useSelector(authData);
  const { path } = useRouteMatch();
  const isOnline = useNavigatorStatus();
  const location = useLocation();
  const [theme, setTheme] = useState("light");
  const isClosing = useRef(false);
  const dispatch = useDispatch();
  const history = useHistory();
  //AUTO LogOUT
  if (userAuthStateValue) {
    getUserDetails(userAuthStateValue)
      .then(() => {})
      .catch((err) => {
        if (err.response.status === 401) {
          Auth.signOut();
          dispatch(awsSignOut());
          Cookies.remove("CloudFront-Policy");
          Cookies.remove("CloudFront-Key-Pair-Id");
          Cookies.remove("CloudFront-Signature");
          Cookies.remove("access_token");
          Cookies.remove("refresh_token");
          Cookies.remove("id_token");
          var padmin = localStorage.getItem("persist:adminRoot");
          localStorage.clear();
          sessionStorage.clear();
          window.onbeforeunload = () => {
            localStorage.clear();
          };
          localStorage.setItem("accessToken", userAuthStateValue.beforeAuth);
          padmin !== null && localStorage.setItem("persist:adminRoot", padmin);
          if (
            config.aws_org_id !== "AXIATA" &&
            config.aws_org_id !== "NSECORP"
          ) {
            history.push({ pathname: "/" });
          } else if (config.aws_org_id === "NSECORP") {
            window.location.href = config.aws_cloudfront_lurl;
          } else {
            window.location.href = `${config.auth_url}logout?response_type=code&client_id=${config.aws_user_pools_web_client_id}&redirect_uri=${config.aws_cloudfront_lurl}&state=STATE&scope=email+openid+profile+aws.cognito.signin.user.admin`;
          }
        }
      });
  }
  useEffect(() => {
    if (userAuthStateValue) {
      const success = sendBeaconRequest("/updateUserActive", "true");
      // The sendBeaconRequest returns a boolean indicating whether the request was queued for sending.
      // You can use this to perform further actions or show a confirmation message to the user.
      if (success) {
        console.log("Beacon request sent successfully.");
      } else {
        console.log("Beacon request failed.");
      }
    }
  }, []);
  useEffect(() => {
    let interval;
    const handleBeforeUnload = () => {
      const success = sendBeaconRequest("/updateUserActive", "false");
      // The sendBeaconRequest returns a boolean indicating whether the request was queued for sending.
      // You can use this to perform further actions or show a confirmation message to the user.
      if (success) {
        console.log("Beacon request sent successfully.");
      } else {
        console.log("Beacon request failed.");
      }
    };
    const handleUnload = () => {
      clearInterval(interval);
      const success = sendBeaconRequest("/updateUserActive", "false");
      if (success) {
        console.log("Beacon request sent successfully.");
      } else {
        console.log("Beacon request failed.");
      }
      // The sendBeaconRequest returns a boolean indicating whether the request was queued for sending.
      // You can use this to perform further actions or show a confirmation message to the user.
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    window.addEventListener("unload", handleUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
      window.removeEventListener("unload", handleUnload);
      clearInterval(interval);
    };
  }, []);
  function sendBeaconRequest(url, data) {
    const jsonData = JSON.stringify({
      oid: config.aws_org_id,
      schema: config.schema,
      ur_id: userAuthStateValue?.uData?.ur_id,
      bool: data,
    });
    // Send the Beacon request
    const success = navigator.sendBeacon(
      `${config.aws_cloud_logic_custom_endpoint}${url}`,
      jsonData
    );
    return success;
  }
  const Alert = ({ isOnline }) => {
    const [showAlert, setShowAlert] = React.useState(false);
    React.useEffect(() => {
      let isMounted = true;
      if (isOnline && showAlert && isMounted) {
        setShowAlert(true);
        setTimeout(() => {
          if (isMounted) {
            setShowAlert(false);
          }
        }, 4000);
      }
      if (!isOnline && isMounted) {
        setShowAlert(true);
      }
      return () => {
        isMounted = false;
      };
    }, [isOnline]);
    return (
      <div
        style={{
          fontFamily: `myfontregular`,
        }}
      >
        {showAlert && (
          <div
            style={{
              color: "white",
              fontFamily: `myfontregular`,
              fontWeight: "500",
              fontSize: "14px",
              padding: "5px 10px",
              width: "fit-content",
              height: "fit-content",
              position: "absolute",
              right: "35%",
              top: 90,
              zIndex: 1000,
              border: "1px solid red",
              borderRadius: "5px",
              background: "red",
            }}
          >
            {isOnline
              ? `You are online`
              : `You are offline please check your connection`}
          </div>
        )}
      </div>
    );
  };
  if (
    (config.aws_org_id === "EXCELSOFT" || userAuthStateValue) &&
    location.pathname !== "/sharingobject"
  ) {
    return (
      <ThemeProvider theme={Theme}>
        <Layout setTheme={setTheme} theme={theme}>
          <AnimatePresence>
            <Switch location={location} key={location.pathname}>
              <Route exact path="/" component={SignInAndSignUp}>
                {userAuthStateValue ? (
                  <Redirect to="/home" />
                ) : (
                  <SignInAndSignUp />
                )}
              </Route>
              <Route exact path="/home" component={LandingPage} />
              <Route exact path="/course" component={Course} />
              <Route exact path="/content" component={RadioObject} />
              <Route
                exact
                path="/learning"
                render={() => (
                  <LearningPath setTheme={setTheme} theme={theme} />
                )}
              />
              <Route exact path="/ExploreViewAll" component={ExploreViewAll} />
              <Route
                exact
                path="/ExploreCourseView"
                component={ExploreCourseView}
              />
              <Route
                exact
                path="/ExploreCourseViews"
                component={ExploreCourseViews}
              />
              <Route exact path="/explore/:name/:id" component={Explore} />
              <Route exact path="/courseview" component={CourseView} />
              <Route exact path="/programview" component={AllProgramView} />
              <Route exact path="/viewallcontent" component={ViewAll} />
              <Route
                exact
                path="/viewallpath"
                component={LearningPathViewAll}
              />
              <Route
                exact
                path="/profile"
                render={() => <MyProfile setTheme={setTheme} theme={theme} />}
              />
              <Route
                exact
                path="/Assignments"
                render={() => (
                  <AllAssignments setTheme={setTheme} theme={theme} />
                )}
              />
              <Route
                exact
                path="/learning/forms/:lpid"
                component={JswFormpage}
              />
              <Route exact path="/bookmarks" component={BookmarkView} />
              <Route exact path="/competency" component={Competency} />
              <Route exact path="/calender" component={Calender} />
              <Route exact path="/contactus" component={ContactUs} />
              <Route exact path="/leaderboard" component={Leaderboard} />
              <Route exact path="/myCoins" component={MyStoreCoins} />
              <Route exact path="/session" component={LiveSession} />
              <Route exact path="/wishlist" component={Wishlist} />
              <Route path="/viewAllCourses" component={ViewAllCourses} />
              <Route path="/viewAllPrograms" component={AllPrograms} />
              <Route path="/viewAllTopics" component={AllTopics} />
              <Route path="/exploreTopics" component={ExploreTopics} />
              <Route path="/discussion" component={Discussion} />
              <Route path="/sakshamlive" component={SakshamLive} />
              <Route
                path="/sakshamlivedetails"
                component={SakshamLiveDetails}
              />
              <Route
                path="/viewAllContinueLearning"
                component={ContinueLearning}
              />
              <Route
                exact
                path="/learningAnalytics"
                component={LearningAnalytics}
              />
              <Route
                exact
                path="/learnerProgress"
                component={LearnerProgress}
              />
              <Route path="/viewAllMyPrograms" component={MyPrograms} />
              <Route exact path="/editProfile" component={EditProfile} />
              <Route
                path="/social-learning/create-post"
                component={CreatePost}
              />
              <Route
                path="/social-learning/viewAllTopic"
                component={ViewAllTopic}
              />
              <Route
                path="/social-learning/FollowPeople"
                component={FollowPeople}
              />
              <Route
                path="/social-learning/InDetails"
                component={SliderInDetails}
              />
              <Route
                path="/social-learning/OtherUserProfile"
                component={OtherUserProfile}
              />
              <Route path="/social-learning/MyPost" component={MyPost} />
              <Route
                path="/social-learning/TeamsProfile"
                component={TeamsProfile}
              />
              <Route path="/social-learning" component={SocialLearning} />
              <Route path="/my-peers" component={MyPeers} />
              <Route path="/compare" component={MyPeersCompare} />
              <Route path="/common-courses" component={MyPeersCommonCourses} />
              <Route
                path="/different-courses"
                component={MyPeersDifferentCourses}
              />
              <Route path="*" component={SignInAndSignUp} />
            </Switch>
          </AnimatePresence>
          {isOnline === false ? <Alert isOnline={isOnline} /> : null}
          {userAuthStateValue && <TimeSpent />}
        </Layout>
      </ThemeProvider>
    );
  } else if (
    config.aws_org_id === "ICICI" &&
    !userAuthStateValue &&
    location.pathname !== "/sharingobject"
  ) {
    return (
      <AnimatePresence>
        <Switch>
          <Route exact path="/" component={SignInIcici} />
          <Route path="*" component={SignInIcici} />
        </Switch>
        {isOnline === false ? <Alert isOnline={isOnline} /> : null}
      </AnimatePresence>
    );
  } else if (
    config.aws_org_id === "NSEKAR" &&
    userAuthStateValue &&
    location.pathname !== "/sharingobject"
  ) {
    return (
      <ThemeProvider theme={Theme}>
        <Layout>
          <AnimatePresence>
            <Switch location={location} key={location.pathname}>
              <Route exact path="/" component={Login}>
                {userAuthStateValue ? <Redirect to="/home" /> : <Login />}
              </Route>
              <Route exact path="/home" component={LandingPage} />
              <Route exact path="/course" component={Course} />
              <Route exact path="/content" component={RadioObject} />
              <Route exact path="/ExploreViewAll" component={ExploreViewAll} />
              <Route
                exact
                path="/learning"
                render={() => (
                  <LearningPath setTheme={setTheme} theme={theme} />
                )}
              />
              <Route
                exact
                path="/ExploreCourseView"
                component={ExploreCourseView}
              />
              <Route
                exact
                path="/ExploreCourseViews"
                component={ExploreCourseViews}
              />
              <Route exact path="/explore/:name/:id" component={Explore} />
              <Route exact path="/courseview" component={CourseView} />
              <Route exact path="/programview" component={AllProgramView} />
              <Route exact path="/viewallcontent" component={ViewAll} />
              <Route
                exact
                path="/profile"
                render={() => <MyProfile setTheme={setTheme} theme={theme} />}
              />
              <Route exact path="/bookmarks" component={BookmarkView} />
              <Route exact path="/calender" component={Calender} />
              <Route exact path="/contactus" component={ContactUs} />
              <Route exact path="/leaderboard" component={Leaderboard} />
              <Route exact path="/myCoins" component={MyStoreCoins} />
              <Route path="/viewAllCourses" component={ViewAllCourses} />
              <Route path="/viewAllPrograms" component={AllPrograms} />
              <Route path="/viewAllTopics" component={AllTopics} />
              <Route path="/exploreTopics" component={ExploreTopics} />
              <Route path="/discussion" component={Discussion} />
              <Route
                path="/viewAllContinueLearning"
                component={ContinueLearning}
              />
              <Route path="/viewAllMyPrograms" component={MyPrograms} />
              <Route exact path="/editProfile" component={EditProfile} />
              <Route path="*" component={Login} />
            </Switch>
          </AnimatePresence>
          {isOnline !== false && <Alert isOnline={isOnline} />}
        </Layout>
      </ThemeProvider>
    );
  } else if (location.pathname === "/sharingobject") {
    return (
      <AnimatePresence>
        <Switch location={location} key={location.pathname}>
          <Route path="/sharingobject" component={SharedObject} />
          <Route exact path="/" component={SignInAndSignUp} />
          <Route path="*" component={SignInAndSignUp} />
        </Switch>
        {isOnline === false ? <Alert isOnline={isOnline} /> : null}
      </AnimatePresence>
    );
  } else if (
    config.aws_org_id !== "NSEKAR" &&
    config.aws_org_id !== "NSECORP" &&
    config.aws_org_id !== "EXCELEARN" &&
    !userAuthStateValue &&
    location.pathname !== "/sharingobject"
  ) {
    return (
      <AnimatePresence>
        <Switch>
          <Route exact path="/login" component={SignInAndSignUp} />
          <Route exact path="/" component={SignInAndSignUp} />
          <Route path="*" component={SignInAndSignUp} />
        </Switch>
        {isOnline === false ? <Alert isOnline={isOnline} /> : null}
      </AnimatePresence>
    );
  } else if (
    config.aws_org_id === "NSEKAR" &&
    !userAuthStateValue &&
    location.pathname !== "/sharingobject"
  ) {
    return (
      <AnimatePresence>
        <Switch>
          <Route exact path="/" component={Login} />
          <Route exact path="/sign-up" component={SignUp} />
          <Route exact path="/confirm-sign-up" component={ConfirmSignUp} />
          <Route exact path="/forgot-password" component={ForgotPassword} />
          <Route exact path="/reset-password" component={ResetPassword} />
          <Route
            exact
            path="/confirm-forgot-password"
            component={ConfirmForgotPassword}
          />
          <Route path="*" component={Login} />
        </Switch>
        {isOnline === false ? <Alert isOnline={isOnline} /> : null}
      </AnimatePresence>
    );
  } else if (
    config.aws_org_id === "NSECORP" &&
    !userAuthStateValue &&
    location.pathname !== "/sharingobject"
  ) {
    return (
      <AnimatePresence>
        <Switch>
          <Route exact path="/" component={ONDCLandingPage} />
          <Route path="*" component={ONDCLandingPage} />
          <Route exact path="/sign-up" component={SignUpONDC} />
          <Route exact path="/confirm-sign-up" component={ConfirmSignUpONDC} />
          <Route exact path="/forgot-password" component={ForgotPasswordONDC} />
          <Route exact path="/reset-password" component={ResetPasswordONDC} />
          <Route
            exact
            path="/confirm-forgot-password"
            component={ConfirmForgotPassword}
          />
        </Switch>
        {isOnline === false ? <Alert isOnline={isOnline} /> : null}
      </AnimatePresence>
    );
  } else if (
    config.aws_org_id === "EXCELEARN" &&
    !userAuthStateValue &&
    location.pathname !== "/sharingobject"
  ) {
    return (
      <AnimatePresence>
        <Switch>
          <Route exact path="/" component={LoginExcelLearn} />
          <Route path="*" component={LoginExcelLearn} />
        </Switch>
        {isOnline === false ? <Alert isOnline={isOnline} /> : null}
      </AnimatePresence>
    );
  }
}
export default App;
