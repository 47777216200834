import React, { useState, useEffect } from "react";
import Amplify, { API } from "aws-amplify";
import { useSelector } from "react-redux";
import { authData } from "./redux/auth/authSlice";
import App from "./App";
import awsExports from "./aws-exports";
import PageLoading from "./components/PageLoading/PageLoading";
import { motion, AnimatePresence } from "framer-motion";
function AppLoad() {
  const [isLoading, setIsLoading] = useState(true);
  const userDetails = useSelector(authData);
  useEffect(() => {
    async function apiload() {
      let domain = window.location.hostname.replace("www.", "");
      try {
        const tokenrequestBody = {
          client_key: "beforeclient_key",
          client_secret: awsExports.secretKey, //'before3872948732!%@&#%#)#*49327493284'
        };
        const tokenjsonString = JSON.stringify(tokenrequestBody);
        const tokenbase64EncodedString = btoa(tokenjsonString);
        const tokenreportInfo = `/getToken?json=${encodeURIComponent(
          tokenbase64EncodedString
        )}`;
        const tokenresponse = await API.get(
          awsExports.aws_cloud_logic_custom_name,
          tokenreportInfo,
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
            },
          }
        );
        localStorage.setItem("accessToken", tokenresponse?.accessToken);
        const requestBody = {
          domain,
          //domain: "www.excelearn.enhanzed.com",
          //domain: "www.oneexcel.enabled.live",
          // domain: "www.dev.enabled.live",
          //domain: "d8fr3pv9nh3co.cloudfront.net",
          //domain: "www.ssfl.enabled.live",
          //  domain: "excelearn.enabled.live",
          //domain: "www.ondc.academy",
          //domain: "www.icicipru.enabled.live",
          //domain: "www.nseacademy.org",
          //domain: "www.feia.nseacademy.org",
          // domain: "www.enabled.live",
          //domain: "www.axiata.enabled.live",
          //domain: "d1wtd0kiwxkajz.cloudfront.net",
          //domain: "www.connected.enabled.live",
          //domain: "www.staging.enabled.live",
          //domain: "www.sakshampro.enabled.live",
          // domain:"www.mea-ssifs.enabled.live"
        };


        const jsonString = JSON.stringify(requestBody);
        const base64EncodedString = btoa(jsonString);
        const reportInfo = `/getConfig?json=${encodeURIComponent(
          base64EncodedString
        )}`;
        const response = await API.get(
          awsExports.aws_cloud_logic_custom_name,
          reportInfo,
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: tokenresponse?.accessToken,
            },
          }
        );
        window.webinvokeurl = response.webinvokeurl;
        setConfig(response);
      } catch (error) {
        console.error(error);
      }
    }
    // setTimeout(apiload, 5000);
    apiload();
  }, []);
  function setConfig(res) {
    var r = document.querySelector(":root");
    r.style.setProperty("--color-grey-dark-1", res.mainColor1);
    r.style.setProperty("--color-grey-dark-2", res.mainColor1);
    r.style.setProperty("--sub-heading-color", res.mainColor2);
    r.style.setProperty("--button-background", res.mainColor2);
    r.style.setProperty("--header-color", res.mainColor2);
    r.style.setProperty("--color-button-background", res.mainColor2);
    r.style.setProperty("--color-primary", res.mainColor1);
    r.style.setProperty(
      "--linear-gradient",
      `linear-gradient(${res.mainColor1}, ${res.mainColor1})`
    );
    document.title = res.title;
    var link = document.querySelector("link[rel~='icon']");
    if (!link) {
      link = document.createElement("link");
      link.rel = "icon";
      document.getElementsByTagName("head")[0].appendChild(link);
    }
    link.href = `https://${
      res.domain
    }/${res?.oid?.toLowerCase()}-resources/images/org-images/App-icon.png`;
    awsExports.aws_org_id = res.oid;
    awsExports.title = res.title;
    awsExports.main_color_1 = res.mainColor1;
    awsExports.main_color_2 = res.mainColor2;
    awsExports.platform_main_theme = `linear-gradient(${res.mainColor1}, ${res.mainColor1})`;
    awsExports.button_color_web = res.mainColor1;
    awsExports.DOMAIN = res.domain;
    awsExports.aws_cloudfront_url = "https://" + res.domain + "/";
    awsExports.aws_cloudfront_lurl = "https://" + res.domain;
    awsExports.gizmos = {};
    awsExports.gizmos.learnerprogress = true;
    awsExports.gizmos.announcements = true;
    awsExports.gizmos.leaderboard = true;
    awsExports.gizmos.certificates = true;
    awsExports.leaderboard = res.leaderboard ?? true;
    awsExports.mycoins = res.mycoins ?? true;
    awsExports.admindomain = res.admindomain;
    if (res.gizmos) {
      awsExports.gizmos.learnerprogress = res.gizmos?.learnerprogress;
      awsExports.gizmos.announcements = res.gizmos?.announcements;
      awsExports.gizmos.leaderboard = res.gizmos?.leaderboard;
      awsExports.gizmos.certificates = res.gizmos?.certificates;
    }

    //start local
    // awsExports.aws_cloudfront_url = "http://localhost:3000/";
    // awsExports.aws_cloudfront_lurl = "http://localhost:3000";
    // end local
    awsExports.aws_cognito_identity_pool_id = res.identitypoolid;
    awsExports.aws_user_pools_id = res.webpoolid;
    awsExports.aws_user_pools_web_client_id = res.webclientid;
    if (res.learningpath) {
      awsExports.learningpath = res.learningpath;
    } else {
      awsExports.learningpath = false;
    }
    if (res.appVersion) {
      awsExports.appVersion = res.appVersion;
    }
    if (res.auth_url) {
      awsExports.auth_url = res.auth_url;
    }
    if (res.SCHEMA_NAME !== undefined) {
      awsExports.schema = res.SCHEMA_NAME;
    }
    if (res.webinvokeurl !== undefined) {
      awsExports.aws_cloud_logic_custom_endpoint = res.webinvokeurl;
      awsExports.aws_cloud_logic_custom_name = res.webapiname;
      awsExports.aws_cloud_logic_custom = [
        {
          id: res.webapiid,
          name: res.webapiname,
          description: "",
          endpoint: res.webinvokeurl,
          region: "ap-south-1",
        },
      ];
    }
    if (res.signUp) {
      awsExports.signUp = res.signUp;
    } else {
      awsExports.signUp = false;
    }
    if (res.defaultTenant) {
      awsExports.defaultTenant = res.defaultTenant;
    } else {
      awsExports.defaultTenant = "";
    }
    if (res.defaultSTID) {
      awsExports.defaultSTID = res.defaultSTID;
    } else {
      awsExports.defaultSTID = 1;
    }
    awsExports.profileSet = res.profileSet;
    awsExports.home = res.home;
    awsExports.header = res.header;
    awsExports.ratingFeature = res.ratingFeature;
    awsExports.course = res.course;
    if (res.socialLearning) {
      awsExports.socialLearning = res.socialLearning;
    } else {
      awsExports.socialLearning = false;
    }
    if (res.certificates) {
      awsExports.certificates = res.certificates;
    } else {
      awsExports.certificates = false;
    }
    if (res.competency) {
      awsExports.competency = res.competency;
    } else {
      awsExports.competency = false;
    }
    if (res.activeUsers) {
      awsExports.activeUsers = res.activeUsers;
    } else {
      awsExports.activeUsers = false;
    }
    if (res.loginImageHide) {
      awsExports.loginImageHide = res.loginImageHide;
    } else {
      awsExports.loginImageHide = false;
    }
    if (res.sakshamlive) {
      awsExports.sakshamlive = res.sakshamlive;
    } else {
      awsExports.sakshamlive = false;
    }
    Amplify.configure(awsExports);
    setIsLoading(false);
  }
  return (
    <AnimatePresence>
      <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
        {isLoading ? <PageLoading /> : <App />}
      </motion.div>
    </AnimatePresence>
  );
}
export default AppLoad;
