import React, { useEffect } from "react";
import "./ViewAll.scss";

import { useSelector, useDispatch } from "react-redux";

import { Grid, makeStyles, Typography } from "@material-ui/core";
import { session } from "../../redux/Session/Session";

import moment from "moment";

import { useHistory, Link } from "react-router-dom";
import { Breadcrumbs } from "@material-ui/core";
import CardLarge from "../../components/Cards/CardLarge/CardLarge";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { authData, awsSignIn } from "../../redux/auth/authSlice";
import config from "../../aws-exports";
import ContentCard from "../../components/Cards/ContentCard/ContentCard";
import CardPrimary from "../../components/Cards/Card.Primary";
import SakshamLiveCard from "../../components/Cards/SakshamLiveCard/SakshamLiveCard";
import defaultIm from "../../assets/defaultItemImage.jpg";

function ViewAll() {
  const { viewAllArr } = useSelector((state) => state.home);
  console.log("log", viewAllArr);
  const userDetails = useSelector(authData);
  const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      marginTop: "2rem",
      display: "flex",
      justifyContent: "center",
    },
    paper: {
      padding: theme.spacing(3),
      height: "600px",
      width: "1100px",
      backgroundColor: "white",
    },
    gridContainer: {
      marginTop: "2rem",
      boxShadow: "rgba(0, 0, 0, 0.25) 0px 5px 41px -2px",
      background:
        userDetails.themeDark === "dark" ? "#282D37 !important" : "#fff",
      padding: "2rem",
      borderRadius: "5px",
      "& > div": {
        display: "flex",
      },
      "@media (max-width: 430px)": {
        marginTop: 0,
        padding: "1rem",
        "& > div > div": {
          margin: "0.8rem",
        },
      },
    },
  }));
  const classes = useStyles();
  const dispatch = useDispatch();
  useEffect(() => {
    window.scrollTo(0, 0);
    if (userDetails === 0) {
      window.location.href = config.aws_cloudfront_lurl;
    }
  }, []);
  const handleFLocation = (
    ttitle,
    tid,
    title,
    otype,
    ref_type,
    sn,
    sid,
    subject,
    start,
    end,
    onlineMeeting,
    organizer
  ) => {
    let breadcrumb = { ...userDetails };

    if (ref_type === 1) {
      breadcrumb.location = {
        from: "Home",
        courseName: ttitle ? ttitle : title,
        viewAll: true,
        viewAllRoute: "/viewallcontent",
      };
      dispatch(awsSignIn(breadcrumb));
      history.push(`course?id=${tid}`);
    } else if (ref_type === 2) {
      breadcrumb.location = {
        from: "Home",
        contentName: ttitle ? ttitle : title,
        viewAll: true,
        viewAllRoute: "/viewallcontent",
      };
      dispatch(awsSignIn(breadcrumb));
      history.push(`content?id=${tid}`);
    } else if (ref_type === 3) {
      breadcrumb.location = {
        from: "Home",
        contentName: sn,
        viewAll: true,
        viewAllRoute: "/viewallcontent",
      };
      dispatch(awsSignIn(breadcrumb));
      history.push(`session?sid=${sid}`);
    } else if (ref_type === 4) {
      let sData = { ...userDetails };

      sData.saksham = {};
      sData.saksham.onlineMeeting = onlineMeeting;
      sData.saksham.organizer = organizer;
      sData.saksham.start = start;
      sData.saksham.end = end;
      sData.saksham.title = subject;

      dispatch(awsSignIn(sData));
      history.push(`/sakshamlivedetails`);
    }
  };

  const history = useHistory();

  return (
    <div>
      <Breadcrumbs
        separator={
          <NavigateNextIcon
            fontSize="large"
            style={{ fill: "var(--color-primary)" }}
          />
        }
        aria-label="breadcrumb"
        style={{ marginTop: "2rem" }}
      >
        <Link
          color="inherit"
          to="/home"
          style={{
            color: "#1974b5",
            fontSize: "1.5rem",
            textDecoration: "none",
            cursor: "pointer",
          }}
        >
          {`${userDetails?.languagejson?.Home_Page?.Home || "Home"}`}
        </Link>
        <Link
          style={{
            color: "#777",
            fontSize: "1.5rem",
            textDecoration: "none",
          }}
          color="inherit"
        >
          {`${userDetails?.languagejson?.Home_Page?.View_all || "View all"}`}
        </Link>
      </Breadcrumbs>
      <div className="flex-between">
        <div className="flex-container">
          <Typography component="h1" variant="h4"></Typography>
        </div>
      </div>

      <Grid container spacing={1} className={classes.gridContainer}>
        {viewAllArr.map(
          ({
            title,
            tid,
            otype,
            ttitle,
            noofnuggets,
            comp_date,
            ref_type,
            sub_date,
            oname,
            dur,
            sortdt,
            sid,
            sn,
            EI,
            date_list,
            startdate,
            stype,
            subject,
            start,
            end,
            onlineMeeting,
            organizer,
          }) => (
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              lg={3}
              onClick={() =>
                handleFLocation(
                  ttitle,
                  tid,
                  title,
                  otype,
                  ref_type,
                  sn,
                  sid,
                  subject,
                  start,
                  end,
                  onlineMeeting,
                  organizer
                )
              }
              key={tid}
            >
              {ref_type === 1 && (
                <CardLarge
                  title={title}
                  tid={tid}
                  count={noofnuggets}
                  comp_date={comp_date}
                  sub_date={sub_date}
                  elseWhere={true}
                />
              )}
              {ref_type === 2 && (
                <ContentCard
                  otype={otype}
                  title={title || oname}
                  dur={dur}
                  elseWhere={true}
                  star={sortdt?.rate}
                />
              )}
              {ref_type === 3 && (
                <CardPrimary
                  auth={true}
                  title={sn}
                  img={
                    sid && config.aws_org_id === "ICICI"
                      ? defaultIm
                      : `${
                          config.aws_cloudfront_url
                        }${config.aws_org_id.toLowerCase()}-resources/images/session-images/${sid}.png`
                  }
                  addToBookMarkList={() => {}}
                  addToFavorite={EI}
                  sessionType={stype}
                  sessionDate={
                    date_list
                      ? date_list[0]?.date === ""
                        ? "yet to be scheduled"
                        : moment(
                            new Date(date_list[0].combineStartTime).getTime()
                          ).format("DD MMM YYYY")
                      : startdate
                      ? moment(parseInt(startdate)).format("DD MMM YYYY")
                      : "yet to be scheduled"
                  }
                  handleLocation={() => {
                    history.push(`/session?sid=${sid}`);
                    // dispatch(session(item));
                  }}
                />
              )}
              {ref_type === 4 && (
                <SakshamLiveCard
                  title={subject}
                  start={start}
                  end={end}
                  onlineMeeting={onlineMeeting}
                  organizer={organizer}
                />
              )}
            </Grid>
          )
        )}
      </Grid>
    </div>
  );
}

export default ViewAll;
