import React, { useEffect, useState } from "react";
import Carousel from "../../components/Carousel/Carousel";
import config from "../../aws-exports";
import { API } from "aws-amplify";
import axios from "axios";
import {
  Button,
  Grid,
  Skeleton,
  Stack,
  Breadcrumbs,
  Typography,
} from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Link } from "react-router-dom";

const SakshamLive = () => {
  const [data, setData] = useState([]);
  const [dataLoad, setDataLoad] = useState(true);

  useEffect(() => {
    getAuth();
  }, []);

  const getAuth = async () => {
    try {
      const reportInfo = `/getMSAuth`;
      const res = await API.get(
        config.aws_cloud_logic_custom_name,
        reportInfo,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );

      getAccessTokenUEvent(res.body.access_token);
    } catch (e) {}
  };

  const getAccessTokenUEvent = async (auth) => {
    try {
      const dayCount = 7; // Fetch events from the last 7 days
      const startDate = new Date();
      startDate.setUTCDate(startDate.getUTCDate() - dayCount);
      const formattedDate =
        startDate.toISOString().split("T")[0] + "T00:00:00Z"; // Format: yyyy-MM-ddT00:00:00Z

      const filterQuery = `?$filter=start/dateTime ge '${formattedDate}'&$top=100`;
      const userResponse = await axios.get(
        `https://graph.microsoft.com/v1.0/users/chilakapati.sreevallabh@iciciprulife.com/calendar/events${filterQuery}`,
        {
          headers: {
            Authorization: `Bearer ${auth}`,
          },
        }
      );

      console.log("API Response:", userResponse.data); // Debugging log

      if (userResponse.data?.value && Array.isArray(userResponse.data.value)) {
        const updatedEvents = userResponse.data.value.map((event) => ({
          ...event,
          ref_type: 4, // Set your custom value
          loading: false,
        }));

        setData(updatedEvents);
      } else {
        console.warn("Unexpected API response format:", userResponse.data);
      }
    } catch (error) {
      console.error("Error fetching events:", error);
    } finally {
      setDataLoad(false); // Ensure this runs even if an error occurs
    }
  };

  const breadcrumbs = [
    <Link
      underline="hover"
      key="1"
      color="inherit"
      to={"/home"}
      style={{ fontSize: "1.5rem", cursor: "pointer", textDecoration: "none" }}
    >
      Home
    </Link>,
    <Link
      key="3"
      color="text.primary"
      style={{
        color: "#777",
        fontSize: "1.5rem",
        textDecoration: "none",
        cursor: "context-menu",
      }}
    >
      Saksham Live
    </Link>,
  ];

  return (
    <div>
      <Breadcrumbs
        separator={
          <NavigateNextIcon
            fontSize="large"
            style={{ color: "var(--color-primary)" }}
          />
        }
        aria-label="breadcrumb"
      >
        {breadcrumbs}
      </Breadcrumbs>

      <div style={{ marginTop: "5rem" }}>
        {dataLoad ? (
          <>
            <Skeleton
              style={{ width: "1200px", height: "30rem" }}
              animation="wave"
              variant="rectangular"
            />
          </>
        ) : (
          <Carousel
            heading="Saksham Live"
            cardData={data}
            loading={dataLoad}
            classDefine="item-saksham"
          />
        )}
      </div>
    </div>
  );
};
export default SakshamLive;
