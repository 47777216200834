import React, { useState } from "react";
import { Wrapper } from "./SakshamSession.Styled";
import {
  HeadingPrimary,
  HeadingSecondary,
  HeadingThree,
} from "../../components/Styles/Heading";
import { Theme } from "../../aws-exports";
import LoadingButton from "@mui/lab/LoadingButton";
import { useSelector, useDispatch } from "react-redux";
import { authData, awsSignIn } from "../../redux/auth/authSlice";
import {
  Button,
  Grid,
  Skeleton,
  Stack,
  Breadcrumbs,
  Typography,
  Paper,
} from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Link } from "react-router-dom";

const StyledButtonJoin = {
  background: "#6a5acd",
  color: Theme.color.white,
  borderColor: "#6a5acd",
  textTransform: "unset",
  my: 1,
  "&:hover": {
    background: "#6a5acd",
    color: Theme.color.white,
    borderColor: "#6a5acd",
  },
};

const SakshamLiveDetails = () => {
  const userDetails = useSelector(authData);

  console.log(userDetails);
  const [gray, setGray] = useState(false);

  const formatDateTime = (dateTimeStr) => {
    const dateObj = new Date(dateTimeStr);

    const formattedDate = new Intl.DateTimeFormat("en-GB", {
      day: "numeric",
      month: "long",
      year: "numeric",
    }).format(dateObj);

    const formattedTime = new Intl.DateTimeFormat("en-US", {
      hour: "numeric",
      minute: "2-digit",
      hour12: true,
    }).format(dateObj);

    // Convert day number to ordinal format (e.g., 12 -> 12th)
    const day = dateObj.getDate();
    const suffix = ["th", "st", "nd", "rd"][
      day % 10 > 3 || Math.floor((day % 100) / 10) === 1 ? 0 : day % 10
    ];

    return `${day}${suffix} ${formattedDate.split(" ")[1]}, ${
      formattedDate.split(" ")[2]
    } | ${formattedTime}`;
  };

  const handleJoin = (url) => {
    window.open(url, "_blank");
  };

  const breadcrumbs = [
    <Link
      underline="hover"
      key="1"
      color="inherit"
      to={"/home"}
      style={{ fontSize: "1.5rem", cursor: "pointer", textDecoration: "none" }}
    >
      Home
    </Link>,
    <Link
      underline="hover"
      key="2"
      color="inherit"
      style={{ fontSize: "1.5rem", cursor: "pointer", textDecoration: "none" }}
      to={"/sakshamlive"}
    >
      Saksham Live
    </Link>,
    <Link
      key="3"
      color="text.primary"
      style={{
        color: "#777",
        fontSize: "1.5rem",
        textDecoration: "none",
        cursor: "context-menu",
      }}
    >
      {userDetails?.saksham?.title}
    </Link>,
  ];

  return (
    <Wrapper>
      <Breadcrumbs
        separator={
          <NavigateNextIcon
            fontSize="large"
            style={{ color: "var(--color-primary)" }}
          />
        }
        aria-label="breadcrumb"
      >
        {breadcrumbs}
      </Breadcrumbs>

      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        marginBottom="3rem"
        marginTop="3rem"
      >
        <HeadingPrimary>{userDetails?.saksham?.title}</HeadingPrimary>
      </Stack>
      <Stack
        direction={{ xs: "column-reverse", md: "row" }}
        spacing={{ xs: 1, md: 4 }}
      >
        <Paper
          elevation={5}
          sx={{
            p: 3,
            borderRadius: "4px",
            textAlign: "justify",
            width: "100%",
          }}
        >
          <Stack direction={{ xs: "column", sm: "column", md: "row" }}>
            <Stack
              direction="column"
              alignItems="center"
              justifyContent="center"
              spacing={2}
              sx={{ flexGrow: 1 }}
            >
              <HeadingSecondary>
                Start time :{" "}
                {formatDateTime(userDetails?.saksham?.start?.dateTime)}
              </HeadingSecondary>
              <HeadingSecondary>
                End time : {formatDateTime(userDetails?.saksham?.end?.dateTime)}
              </HeadingSecondary>
              <Stack
                direction="column"
                justifyContent="center"
                alignItems="center"
              >
                Organizer: {userDetails?.saksham.organizer.emailAddress.name}
              </Stack>
              <Stack
                direction="column"
                justifyContent="center"
                alignItems="center"
              >
                <LoadingButton
                  variant="contained"
                  loading={false}
                  sx={StyledButtonJoin}
                  disabled={gray ? true : false}
                  onClick={() =>
                    handleJoin(userDetails.saksham.onlineMeeting.joinUrl)
                  }
                >
                  Join
                </LoadingButton>
              </Stack>
            </Stack>
          </Stack>
        </Paper>
      </Stack>
    </Wrapper>
  );
};

export default SakshamLiveDetails;
