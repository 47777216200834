import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { authData, awsSignIn } from "../../redux/auth/authSlice";
import CompareIcon from "@mui/icons-material/Compare";
import ForumOutlinedIcon from "@mui/icons-material/ForumOutlined";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Stack, Typography, Breadcrumbs, Box, Tab, Tabs } from "@mui/material";
import { Link } from "react-router-dom";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { makeStyles } from "@material-ui/core/styles";
import TabContext from "@mui/lab/TabContext";
import TabPanel from "@mui/lab/TabPanel";
import avatar1 from "../../assets/male2.png";
import avatar2 from "../../assets/male3.png";
import soonGif from "../../assets/soon.gif";
import BACoin from "../../assets/Balance Available Coins.png";
import config from "../../aws-exports"
import { getMyPeerDetails } from "../../api/api";
// Sample Image Importation
import { useQuery } from "@tanstack/react-query";
import PropTypes from "prop-types";
import CircularProgress from "@mui/material/CircularProgress";
import { styled } from "@mui/system";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import CardLarge from "../Cards/CardLarge/CardLarge";
import Skeleton from "@mui/material/Skeleton";
function Compare() {
  const userDetails = useSelector(authData);
  let dispatch = useDispatch();
  const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      marginTop: "2rem",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    responsive: {
      [theme.breakpoints.up(1300)]: {
        fontSize: "1rem",
      },
    },
    mainContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "start",
      "@media (max-width: 550px)": {
        marginBottom: "1rem",
      },
    },
    loaderSkeleton: {
      width: "200px",
      height: "3rem",
      "@media (max-width: 750px)": {
        width: "200px",
      },
    },
    loaderSkeletonTabs: {
      height: "10rem",
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
    },
    LPMainContainer: {
      width: "100%",
      boxShadow:
        userDetails.themeDark === "dark"
          ? "none !important"
          : "0 0 6px 0 #6666665c",
      backgroundColor:
        userDetails.themeDark === "dark"
          ? "#272C38 !important"
          : theme.palette.background.paper,
      borderRadius: "5px",
    },
    breadcrumbNameStyle: {
      fontSize: "1.5rem!important",
      color:
        userDetails.themeDark === "dark"
          ? "white !important"
          : "black !important",
      fontFamily: `myfontregular!important`,
    },
    mainTab: {
      textTransform: "capitalize!important",
      fontSize: "2rem!important",
      paddingTop: "0!important",
      fontFamily: `myfontregular!important`,
      fontWeight: "800!important",
    },
    boxStyle: {
      borderBottom: 1,
      backgroundColor: "transparent",
      borderColor: "divider",
      overflow: "auto!important",
      "& > div": {
        "@media (max-width: 768px)": {
          width: "70vh",
        },
        "& > div > div > .Mui-selected": {
          background: userDetails.themeDark === "dark" ? "#f2f5f7" : "#f2f5f7",
          color: "var(--color-button-background)!important",
        },
      },
    },
    avatarIcon: {
      width: "10rem",
      margin: "auto",
    },
    card_image: {
      height: "150px",
      width: "100%",
    },
    card_content: {
      display: "flex",
      flexDirection: "column",
      alignItems: "left",
      padding: "10px 14px",
    },
    imageContent: {
      position: "relative",
      rowGap: "5px",
      padding: "0",
    },
    card: {
      borderRadius: "2px",
      backgroundColor: "#FFF",
      boxShadow: "#cdcdcd40 0px 0px 4px 5px",
      margin: "0 12px",
      overflow: "hidden",
    },
    mainCompareSection: {
      width: "fit-content",
      boxShadow:
        userDetails.themeDark === "dark"
          ? "none !important"
          : "0 0 6px 0 #8787875c",
      padding: "6px 20px 20px",
      margin: "auto",
      borderRadius: "6px",
      backgroundColor: userDetails.themeDark === "dark" ? "#353944" : "#fff",
    },
    noteSection: {
      padding: "4px 15px",
      margin: "6px auto 20px",
      backgroundColor: "#d9f7e0",
      color: "#55bf55",
      boxShadow:
        userDetails.themeDark === "dark"
          ? "none !important"
          : "0 0 6px 0 #dadada5c",
      width: "fit-content",
      fontSize: "1.5rem",
      borderRadius: "6px",
    },
    mainbodySection: {
      display: "flex",
      alignItems: "center",
      gap: "2rem",
      alignItems: "start",
    },
    mainbodySection1: {
      display: "flex",
      alignItems: "center",
      gap: "5rem",
      [theme.breakpoints.down(420)]: {
        gap: "2rem",
      },
    },
    mainbodySection2: {
      display: "flex",
      alignItems: "center",
      gap: "5rem",
      [theme.breakpoints.down(700)]: {
        display: "grid",
        textAlign: "center",
      },
      [theme.breakpoints.down(420)]: {
        gap: "2rem",
      },
    },
    totalCoins: {
      padding: "6px",
      border: "1.5px solid #f1b47f",
      display: "flex",
      alignItems: "center",
      gap: "2px",
      borderRadius: "4px",
    },

    mainCompareSection2: {
      boxShadow:
        userDetails.themeDark === "dark"
          ? "none !important"
          : "0 0 6px 0 #8787875c",
      padding: "20px",
      margin: "2rem 0rem",
      borderRadius: "6px",
    },
    headerSection: {
      marginBottom: "20px",
      textAlign: "center",
    },
    mainCompareSection3: {
      boxShadow:
        userDetails.themeDark === "dark"
          ? "none !important"
          : "0 0 2px 0 #8787875c",
      padding: "20px 55px",
      margin: "2rem 0 0",
      borderRadius: "6px",
      width: "400px",
      backgroundColor: userDetails.themeDark === "dark" ? "#353944" : "#edf5fa",
      [theme.breakpoints.down(540)]: {
        padding: "20px",
      },
    },
    mainSection: {
      display: "flex",
      gap: "2rem",
      flexWrap: "wrap",
      [theme.breakpoints.down(1050)]: {
        justifyContent: "center",
      },
    },
    mainbodyGraphSection: {
      display: "grid",
      textAlign: "center",
    },
    headerSectionMain: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      "& > a": {
        textDecoration: "none",
        fontWeight: "600",
        color: "var(--color-primary)",
        display: "flex",
        alignItems: "center",
      },
    },
  }));

  const classes = useStyles();

  const [value, setValue] = useState("1");
  const [commonData, setCommonData] = useState([]);
  const [differentData, setDifferentData] = useState([]);
  const [allCommonData, setAllCommonData] = useState([]);
  const [loader, setLoader] = useState(true);
  const { data: userData, isLoading: spin } = useQuery({
    queryKey: ["peerDet"],
    queryFn: () => getMyPeerDetails(userDetails, false),
    onSuccess: (data) => {
      handlefilter(data);
      setLoader(false); // Data successfully loaded
    },
  });
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handlefilter = (userDataD) => {
    const matchedData = userDataD?.my_ur_id?.courses
      .map((obj1) => {
        const matchedObj = userDataD?.compare_ur_id?.courses.find(
          (obj2) => obj2.tid === obj1.tid
        );
        if (matchedObj) {
          let user_One = {
            cobj: obj1?.cobj,
            tobj: obj1?.tobj,
            comp_date: obj1?.comp_date,
          };
          let userOne_prog = (user_One.cobj / user_One.tobj) * 100;

          let user_Two = {
            cobj: matchedObj?.cobj,
            tobj: matchedObj?.tobj,
            comp_date: matchedObj?.comp_date,
          };
          let userTwo_prog = (user_Two.cobj / user_Two.tobj) * 100;
          return {
            tn: obj1.tn,
            tid: obj1.tid,
            user_One: userOne_prog,
            user_Two: userTwo_prog,
            user_One_name: userDataD?.my_ur_id?.user_details[0]?.first_name,
            user_two_name:
              userDataD?.compare_ur_id?.user_details[0]?.first_name,
            user_one_comp: user_One?.comp_date,
            user_two_comp: user_Two?.comp_date,

            // Calculate and format progress
          };
        }
        return null;
      })
      .filter((item) => item !== null);

    const limitedMatchedData = matchedData.slice(0, 3);
    const different = userDataD?.compare_ur_id?.different_courses.slice(0, 3);
    setDifferentData(different);
    let sdata = { ...userDetails };

    sdata.allCommonData = matchedData;
    sdata.alldifferentData = userDataD?.compare_ur_id?.different_courses;
    dispatch(awsSignIn(sdata));
    console.log(matchedData);
    setAllCommonData(matchedData);
    setCommonData(limitedMatchedData);
  };

  // Filter and match based on tid

  const breadcrumbs = [
    <Link
      underline="hover"
      key="1"
      color="inherit"
      to={"/home"}
      style={{ fontSize: "1.5rem", cursor: "pointer", textDecoration: "none" }}
    >
      {`${userDetails?.languagejson?.Home_Page?.Home || "Home"}`}
    </Link>,
    <Link
      underline="hover"
      key="2"
      color="inherit"
      to={"/my-peers"}
      style={{ fontSize: "1.5rem", cursor: "pointer", textDecoration: "none" }}
    >
      {`${userDetails?.languagejson?.Active_User?.MyPeers || "My Peers"}`}
    </Link>,
    <Typography
      key="3"
      color="text.primary"
      className={classes.breadcrumbNameStyle}
    >
      {`${userDetails?.languagejson?.Active_User?.Compare || "Compare"}`}
    </Typography>,
  ];

  const BlackCircularProgress = styled(CircularProgress)({
    color: "#707070", // for background
  });

  const BlueCircularProgress = styled(CircularProgress)(({ value }) => ({
    color: value === 100 ? "#48bc48" : "#f6c55d", // for the progress
    position: "absolute",
    "& .MuiCircularProgress-circle": {
      strokeLinecap: "round", // rounded caps
    },
  }));

  const renderComparisonMessage = (userData) => {
    const myPoints = userData?.my_ur_id?.user_details[0]?.points;
    const comparePoints = userData?.compare_ur_id?.user_details[0]?.points;

    if (parseInt(myPoints) > parseInt(comparePoints)) {
      return `You lead your peer by ${myPoints - comparePoints} coins`;
    } else if (parseInt(myPoints) < parseInt(comparePoints)) {
      return `Your peer leads you by ${comparePoints - myPoints} coins`;
    } else {
      return `You and your peer have the same coins`;
    }
  };

  function CircularProgressWithLabel(props) {
    return (
      <Box
        sx={{
          position: "relative",
          display: "inline-flex",
          width: 100,
          height: 100,
        }}
      >
        <BlackCircularProgress
          variant="determinate"
          value={100}
          sx={{ width: "100% !important", height: "100% !important" }}
        />
        <BlueCircularProgress
          variant="determinate"
          {...props}
          sx={{ width: "100% !important", height: "100% !important" }}
        />
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: "absolute",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography
            variant="caption"
            component="div"
            color="text.secondary"
            sx={{
              fontSize: 20,
              color: userDetails.themeDark === "dark" ? "#fff" : "#000",
            }}
          >
            {`${Math.round(props.value)}%`}
          </Typography>
        </Box>
      </Box>
    );
  }

  CircularProgressWithLabel.propTypes = {
    /**
     * The value of the progress indicator for the determinate variant.
     * Value between 0 and 100.
     * @default 0
     */
    value: PropTypes.number.isRequired,
  };

  const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip
      {...props}
      arrow
      classes={{ popper: className }}
      placement="left"
    />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: "black",
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "black",
      fontSize: "1.2rem",
    },
  }));

  return (
    <>
      <Stack spacing={2} style={{ marginBottom: "2rem" }}>
        <Breadcrumbs
          separator={
            <NavigateNextIcon
              fontSize="large"
              style={{ color: "var(--color-primary)" }}
            />
          }
          aria-label="breadcrumb"
        >
          {breadcrumbs}
        </Breadcrumbs>
      </Stack>

      <div className="flex-between">
        <div className={classes.mainContainer}>
          <h3 style={{ fontWeight: "600" }} className="responsive">
            {`${userDetails?.languagejson?.Active_User?.Compare || "Compare"}`}
          </h3>
        </div>
      </div>

      <div className={classes.root}>
        <div className={classes.LPMainContainer}>
          <TabContext value={value}>
            <Box className={classes.boxStyle}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
                indicatorColor="primary"
                textColor="inherit"
                variant="fullWidth"
              >
                <Tab
                  label={`${userDetails?.languagejson?.Active_User?.Compare ||
                    "Compare"}`}
                  value="1"
                  className={classes.mainTab}
                  icon={
                    <CompareIcon
                      style={{
                        fontSize: 22,
                        position: "relative",
                        left: "60px",
                        top: "25px",
                        margin: 0,
                      }}
                    />
                  }
                />

                <Tab
                  label={`${userDetails?.languagejson?.Active_User?.Chat ||
                    "Chat"}`}
                  value="2"
                  className={classes.mainTab}
                  icon={
                    <ForumOutlinedIcon
                      style={{
                        fontSize: 25,
                        position: "relative",
                        left: "50px",
                        top: "27px",
                        margin: 0,
                      }}
                    />
                  }
                />
              </Tabs>
            </Box>
            {/* Tab 1 */}
            <TabPanel
              value="1"
              className={classes[".css-13xfq8m-MuiTabPanel-root"]}
            >
              {loader ? (
                <>
                  <Skeleton variant="rectangular" width={810} height={118} />
                </>
              ) : (
                <div className={classes.mainCompareSection}>
                  <div className={classes.noteSection}>
                    {renderComparisonMessage(userData)}
                  </div>
                  <div className={classes.mainbodySection2}>
                    <div className={classes.mainbodySection}>
                      <div>
                        <img
                          src={avatar1}
                          alt="Avatar Name"
                          title="Avatar Name"
                          className={classes.avatarIcon}
                        />
                      </div>
                      <div style={{ display: "grid", gap: "6px" }}>
                        <h4>{`${userData?.my_ur_id?.user_details[0]?.first_name} ${userData?.my_ur_id?.user_details[0]?.last_name}`}</h4>
                        <span style={{ color: "gray" }}>
                          {`${userDetails?.languagejson?.Active_User?.Level ||
                            "Level"} ${
                            userData?.my_ur_id?.user_details[0]?.levels
                          }`}{" "}
                        </span>
                        <div className={classes.totalCoins}>
                          <img
                            src={BACoin}
                            alt="Coins Available"
                            title={config.aws_org_id.toLocaleLowerCase() === "icici" ? "Points Available": "Coins Available"}
                            style={{ width: "2rem", height: "2rem" }}
                          />
                          <span>
                            {userData?.my_ur_id?.user_details[0]?.points}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div>
                      <CompareIcon
                        style={{ fontSize: 30, color: "var(--color-primary)" }}
                      />
                    </div>
                    <div className={classes.mainbodySection}>
                      <div>
                        <img
                          src={avatar2}
                          alt="Avatar Name"
                          title="Avatar Name"
                          className={classes.avatarIcon}
                        />
                      </div>
                      <div style={{ display: "grid", gap: "6px" }}>
                        <h4>{`${userData?.compare_ur_id?.user_details[0]?.first_name} ${userData?.compare_ur_id?.user_details[0]?.last_name}`}</h4>
                        <span style={{ color: "gray" }}>
                          {`${userDetails?.languagejson?.Active_User?.Level ||
                            "Level"} ${
                            userData?.compare_ur_id?.user_details[0]?.levels
                          }`}{" "}
                        </span>
                        <div className={classes.totalCoins}>
                          <img
                            src={BACoin}
                            alt="Coins Available"
                            title={config?.aws_org_id?.toLocaleLowerCase =="icici" ? "Points Available": "Coins Available"}
                            style={{ width: "2rem", height: "2rem" }}
                          />
                          <span>
                            {userData?.compare_ur_id?.user_details[0]?.points}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              <div className={classes.mainCompareSection2}>
                <div className={classes.headerSectionMain}>
                  <h3 style={{ fontWeight: "600" }} className="responsive">
                    {`${userDetails?.languagejson?.Active_User?.CommonCourses ||
                      "Common Courses"}`}
                  </h3>
                  <Link to="/common-courses">
                    {`${userDetails?.languagejson?.Home_Page?.View_all ||
                      "View all"}`}
                    <ChevronRightIcon style={{ fontSize: "2rem" }} />
                  </Link>
                </div>
                <div className={classes.mainSection}>
                  {commonData.length === 0 ? (
                    <>
                      <Skeleton
                        variant="rectangular"
                        width={1500}
                        height={118}
                      />
                    </>
                  ) : (
                    commonData.map((course) => (
                      <div className={classes.mainCompareSection3}>
                        <h4 className={classes.headerSection}>{course.tn}</h4>
                        <div className={classes.mainbodySection1}>
                          <div className={classes.mainbodyGraphSection}>
                            <BootstrapTooltip
                              title={`${course.user_One_name}'s course progress`}
                            >
                              <div>
                                <CircularProgressWithLabel
                                  value={
                                    course.user_one_comp ? 100 : course.user_One
                                  }
                                />
                              </div>
                            </BootstrapTooltip>
                            <h4>{`${userDetails?.languagejson?.Active_User
                              ?.Completion || "Completion"}`}</h4>
                          </div>
                          <div
                            style={{
                              backgroundColor: "#dbe7ee",
                              width: "1px",
                              height: "8rem",
                            }}
                          ></div>
                          <div className={classes.mainbodyGraphSection}>
                            <BootstrapTooltip
                              title={`${course.user_two_name}'s course progress`}
                            >
                              <div>
                                <CircularProgressWithLabel
                                  value={
                                    course.user_two_comp ? 100 : course.user_Two
                                  }
                                />
                              </div>
                            </BootstrapTooltip>
                            <h4>{`${userDetails?.languagejson?.Active_User
                              ?.Completion || "Completion"}`}</h4>
                          </div>
                        </div>
                      </div>
                    ))
                  )}
                </div>
              </div>

              <div className={classes.mainCompareSection2}>
                <div className={classes.headerSectionMain}>
                  <h3 style={{ fontWeight: "600" }} className="responsive">
                    {`${userDetails?.languagejson?.Active_User
                      ?.DifferentCourses || "Different Courses"}`}
                  </h3>
                  <Link to="/different-courses">
                    {`${userDetails?.languagejson?.Home_Page?.View_all ||
                      "View all"}`}
                    <ChevronRightIcon style={{ fontSize: "2rem" }} />
                  </Link>
                </div>
                <div style={{ padding: "2rem 0" }}>
                  <div
                    className="card swiper-slide"
                    style={{ display: "flex" }}
                  >
                    {loader ? (
                      <>
                        <Skeleton
                          variant="rectangular"
                          width={1500}
                          height={118}
                        />
                      </>
                    ) : (
                      differentData.map((diffr, idx) => (
                        <CardLarge
                          title={diffr?.tn}
                          tid={diffr.tid}
                          count={diffr.noofnuggets}
                          star={diffr.ravg}
                          comp_date={diffr.comp_date}
                          sub_date={diffr.sub_date}
                          bookmark={diffr.bk}
                          heading={"Different Courses"}
                          cobj={diffr.cobj}
                          tobj={diffr.tobj}
                          index={idx}
                          elseWhere={true}
                        />
                      ))
                    )}
                  </div>
                </div>
              </div>
            </TabPanel>

            {/* Tab 2 */}
            <TabPanel
              value="2"
              className={classes[".css-13xfq8m-MuiTabPanel-root"]}
              style={{ textAlign: "center" }}
            >
              <img src={soonGif} alt="Coming soon" style={{ width: "40%" }} />
            </TabPanel>
          </TabContext>
        </div>
      </div>
    </>
  );
}
export default Compare;
